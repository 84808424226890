import {
    Dialog,
    Toast
} from 'vant';
import api from "@/request/api"
import log from "@/request/log"
export default {
    data() {
        return {
            counting: false,
            countDown: 60,
            bg: require("@/assets/login/图层0.png"),
            phoneNumber: '',
            code: '',
            checked: false,
            form_detail: [{
                    url: require("@/assets/login/icon-微信@2x.png"),
                },
                {
                    url: require("@/assets/login/icon-微博@2x.png"),
                },
                {
                    url: require("@/assets/login/icon-抖音@2x.png"),
                },
                {
                    url: require("@/assets/login/icon-哔哩哔哩@2x.png"),
                },
            ],
            startTime: null,
        }
    },

    components: {
        Dialog
    },
    methods: {
        login() {
            log.info({
                p: "l",
                a: "c",
                o: "loginBtn"
            });
            if (this.phoneNumber.length !== 11) {
                Toast('请输入正确的手机号')
                return
            }
            if (this.code.length !== 6) {
                Toast('请输入正确的验证码')
                return
            }
            if (this.checked == false) {
                Toast("请勾选协议")
                return
            }
            api.login(
                this.phoneNumber, this.code
            ).then(res => {
                Toast.success('登录成功');
                this.$router.push("/sign")
                localStorage.setItem("phoneNumber", this.phoneNumber)
            }).catch(err => {
                log.error({
                    p: "l",
                    a: "e",
                });
                Toast.fail('登录失败');
            })

        },
        //调用发送验证码接口
        apiSendcode() {
            let params = {
                "phoneNumber": this.phoneNumber
            }
            api.smscode(
                params
            ).then(res => {
                // 发送成功，开始倒计时
                this.countDown = 60
                this.counting = true
                const timer = setInterval(() => {
                    if (this.countDown <= 0) {
                        clearInterval(timer)
                        this.counting = false
                        return
                    }
                    this.countDown--
                }, 1000)

            }).catch(err=>{
                log.error({
                    p: "l",
                    a: "e",
                });
            })
        },


        sendCode() {
            log.info({
                p: "l",
                a: "c",
                o: "sendCodeBtn"
            });
            if (this.phoneNumber.length !== 11) {
                Toast('请输入正确的手机号')
                return
            }
            if (this.checked == false) {
                Dialog.confirm({
                        title: '用户协议及隐私保护',
                        message: '为了更好地保障您的合法权益,请您阅读并同意以下协议 《用户协议》 《隐私政策》',
                        confirmButtonText: '同意',
                        cancelButtonText: '不同意',

                    }).then(() => {
                        this.checked = true
                        this.apiSendcode()
                    })
                    .catch(() => {
                        // on cancel
                    });;
                return
            } else {
                this.apiSendcode()
            }

        }
    },
    created() {

    },
    mounted() {
        log.info({
            p: "l",
            a: "m",
        });
        // 记录页面打开时间
        this.startTime = new Date()
    },
    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
            p: "l",
            a: "u",
            d: stayTime
        });
    },

}