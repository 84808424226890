import {
    getUser
} from "@/request/api"
import api from "@/request/api"
import log from "@/request/log"
import wx from 'weixin-js-sdk';
import axios from 'axios';
import Clipboard from 'clipboard'
import {
    Dialog,
    Toast
} from 'vant';
import draggable from "vuedraggable";
export default {
    data() {
        return {
            openId: "",
            files: [],
            currentIndex: 0,
            show: false,
            content: [],
            pollCount: 0
        }
    },

    mounted() {
        log.info({
            p: "u",
            a: "m",
        });
        // 记录页面打开时间
        this.getOpenId();
        this.upload();
    },

    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
            p: "u",
            a: "u",
            d: stayTime
        });
    },
    components: {
        Dialog,
        draggable

    },
    computed: {

    },



    methods: {
        change(event) {
            console.log("change", event);
        },
        start(event) {
            console.log("start", event);
        },
        end(event) {
            console.log("end", event, this.files);
        },
        move(event, orgin) {
            console.log("move", event, orgin);
        },


        getOpenId() {
            this.openId = this.$route.query.openId
        },
        upload() {
            this.$refs.fileInput.click();
        },

        handleInputClick(event) {
            const files = Array.from(event.target.files);
            this.handleUpload(files);
        },
        handleUpload(files) {

            const disallowedTypes = [
                "pdf"
            ];
            // 存储不允许上传的文件后缀
            const disallowedExtensions = [];
            if (files.length == undefined) {
                const fileExtension = files.name.split(".").pop().toLowerCase();
                // 判断文件扩展名是否在不允许上传的文件类型数组中
                if (disallowedTypes.indexOf(fileExtension) == -1) {
                    // 文件类型不允许，阻止上传
                    this.$toast(`暂不支持上传${fileExtension}文件格式`);
                    return false;
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    // 将文件信息添加到数组中，可以根据需要自定义文件对象的属性

                    const list = files[i];

                    // 获取文件的扩展名
                    const fileExtension = list.name.split(".").pop().toLowerCase();
                    // 判断文件扩展名是否在不允许上传的文件类型数组中
                    if (disallowedTypes.indexOf(fileExtension) == -1) {
                        // 文件类型不允许，阻止上传
                        disallowedExtensions.push(fileExtension);
                    }
                }
                // 判断是否有不允许上传的文件后缀
                if (disallowedExtensions.length > 0) {
                    // 将不允许上传的文件后缀显示出来
                    const disallowedExtensionsString = disallowedExtensions.join(", ");
                    this.$toast(`暂不支持上传${disallowedExtensionsString}文件格式`);
                    return false;
                }

            }
            const formData = new FormData();
            formData.append("openId", this.openId);
            if (files.length == undefined) {
                formData.append("files", files);
            } else {
                for (let i = 0; i < files.length; i++) {
                    formData.append("files", files[i]);
                }
            }
            const baseUrl = process.env.VUE_APP_API_URL;
            axios
                .post(`${baseUrl}fileToOcr`, formData, {
                    headers: {
                        apiVersion: "0.1.0",
                    },
                })
                .then((response) => {
                    let ids = null
                    if(response.data.length > 1){
                        ids = response.data.join(',')
                     }else{
                        ids = response.data.join('')
                     }

                     console.log(ids)
           
                     wx.miniProgram.navigateTo({url: '/pages/focr/focr?id='+ids})
                })
                .catch((error) => {
                    console.error(error);
                });
        },


    }

}