import {
    getUser
} from "@/request/api"
import api from "@/request/api"
import log from "@/request/log"
import wx from 'weixin-js-sdk';
import axios from 'axios';
import Clipboard from 'clipboard'
import {
    Dialog,
    Toast
} from 'vant';
export default {
    data() {
        return {
            //
            selectedFileIndex: 0,
            fileList: [],
            isAllFormatsTriggered: false,
            id:"",
            ticket: "",
            list: "",
            path: "",
            url: "",
            fileName: "",
            size: "",
            MobileModel: "",
            iswx: "",
            showPopup: false
        }
    },
 
    mounted() {
        log.info({
            p: "u",
            a: "m",
        });
        // 记录页面打开时间
        this.startTime = new Date()
        this.getBandle();
        //this.getShare()
        
        
    },
   
    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
            p: "u",
            a: "u",
            d: stayTime
        });
    },
    components: {
        Dialog,

    },
    computed:{
        containerClass(){
            return this.iswx ? "container" : "othercontainer";
        },
        buttonClass() {
            return this.iswx ? "downloadBtn" : "otherButtonClass";
        },
        isSelectedStyle() {
            return function(item) {
              if (item.isSelected) {
                return {
                  color: 'blue'
                };
              }
              return {};
            };
          }
    },
    
    
   
    methods: {
        
        getBandle(){
            const userAgent = navigator.userAgent;
            this.MobileModel = this.getMobileModel(userAgent);
    
            this.iswx = this.isWeChatBrowser();
       
            if (this.iswx) {
                this.getFileId();
                this.getShare();
            } else {
                this.getFileId();
                this.getShare();
                this.downloadFile();
            }
        },
        isWeChatBrowser() {
            const userAgent = navigator.userAgent.toLowerCase();
            return /micromessenger/.test(userAgent);
        },
        getMobileModel(userAgent) {
            // 根据特定关键词提取手机型号
            if (/iPhone/.test(userAgent)) {
                return "iphone";
            }
        },
        handleClose(){
            this.showPopup = false
        },
        getFileId() {
            const baseUrl = process.env.VUE_APP_API_URL;

            this.id = this.$route.query.id
    
            this.ticket = this.$route.query.ticket
           
            this.path = `${baseUrl}${this.id}?type=ocr&ticket=${this.ticket}&fileType=`

    
            this.url = this.path+ ".xlsx"

            this.fileName = this.$route.query.fileName;
   
            const onlineFile = {
                id: this.id,
                url: this.url,
                fileName: this.fileName + ".xlsx",
                imgUrl: require('@/assets/download/excel.png'),
                isSelected: false,
                showCheck: false 
            };
              
            this.fileList.push(onlineFile);

            console.log(this.fileList)
        },
        downloadFile() {
            if (this.MobileModel == "iphone" && this.iswx == true) {
                this.showPopup = true;
                this.$router.push({path: `/share?id=${this.id}&ticket=${this.ticket}&fileName=${this.fileName}`});
            }else{
                const link = document.createElement("a");
                link.href = this.url;
                link.click();
            }
     
        },


        getFileById(id, ticket) {
            const baseUrl = process.env.VUE_APP_API_URL;
            axios
                .get(`${baseUrl}ocr/file/${id}`, {
                    headers: {
                        apiVersion: "0.1.0",
                        ticket: ticket,
                    },
                })
                .then((response) => {
                    this.fileName = response.data.memo
                })
                .catch((error) => {
                    console.error(error);
                });
        },


        //
        getShare() {
            api.sharenOnceStr({url: encodeURIComponent(location.href.split('#')[0])}).then(res => {
                this.list = res;
            }).catch(err => {
                log.error({
                    p: "h",
                    a: "m",
                })
            })
        },
        share() {
            this.initWXShare(this.list.appId,this.list.timestamp, this.list.nonceStr, this.list.signature);
        },

        initWXShare(appId,timestamp, nonceStr, signature) {
            wx.config({
                debug: true,
                appId: appId,
                timestamp: timestamp,
                nonceStr: nonceStr,
                signature: signature,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone']
            });
            wx.error((res) => {
                console.log('微信分享配置出错：', res);
            });
            

            wx.ready(() => {
                wx.checkJsApi({
                    jsApiList: [
                      // 所有要调用的 API 都要加到这个列表中
                      "updateAppMessageShareData"
                    ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function (res) {
                      console.log('checkJsApi成功=====', res);
                    },
                    fail:function(e){
                      alert('不支持');
                    }
                })
                wx.updateAppMessageShareData({
                    title: '文档圈',
                    link: 'm.filequan.com', //注意，该链接域名或路径必须与当前页面对应的公众号js安全域名一致
                    imgUrl: '',
                    trigger: function (res) {
                      // 不要尝试在trigger中使用ajax异步请求修改本次分享的内容，因为客户端分享操作是一个同步操作，这时候使用ajax的回包会还没有返回
                      alert('用户点击分享');
                    },
                    success: function (res) {
                      alert('已分享');
                    },
                    cancel: function (res) {
                      alert('已取消');
                    },
                    fail: function (res) {
                      alert("fail"+JSON.stringify(res));
                    }
                  });
            })
        },
        //展示全部格式文件
        allFormats() {
            if (this.isAllFormatsTriggered) {
                return;
            }
            const formats = [".txt", ".pdf", "_d.pdf", ".docx"];
            const fileImages = {
              ".txt": require('@/assets/download/txt.png'),
              ".pdf": require('@/assets/download/pdf.png'),
              "_d.pdf": require('@/assets/download/d_pdf.png'),
              ".docx": require('@/assets/download/word.png')
            };
            
            for (const format of formats) {
              const onlineFile = {
                id: this.id,
                url: this.path + format,
                fileName: this.fileName + format,
                imgUrl: fileImages[format],
                isSelected: false,
                showCheck: false 
              };
              
              this.fileList.push(onlineFile);
            }
            this.isAllFormatsTriggered = true;
        },
        handleFileClick(file, index) {
            // 取消上一个选中的文件样式
            if (this.selectedFileIndex !== undefined && this.selectedFileIndex !== index) {
              this.fileList[this.selectedFileIndex].isSelected = false;
              this.fileList[this.selectedFileIndex].showCheck = false;
            }
            
            // 设置当前选中的文件样式
            file.isSelected = !file.isSelected;
            file.showCheck = !file.showCheck;
            this.selectedFileIndex = index;
            
            // 获取选中文件的对象信息
            const selectedFileInfo = this.fileList[this.selectedFileIndex];
            this.url = selectedFileInfo.url
            console.log(selectedFileInfo);
        },
        copyUrl(){
            const clipboard = new Clipboard('.icon', {
                text: () => {
                    return this.url
                }
            })
            clipboard.on('success', () => {
                this.$toast('复制成功')
            })
            clipboard.on('error', () => {
                this.$toast('复制失败')
            })
            clipboard.onClick(event)
            clipboard.destroy()
        }


       

    }
}