<template>
    <div>
        <template>
        <van-tabbar v-model="active" >
            <van-tabbar-item :to="(item.name)" v-for="(item,index) in tabbars" :key="index">
                    <span>{{item.title}}</span>
                  <template #icon="props">
                    <img :src="props.active ? item.active : item.normal" />
                  </template>
            </van-tabbar-item>
        </van-tabbar>
       </template>
    </div>
</template>

<script>
export default {
    data () {
        return {
           
            tabbars:[
                {
                    name: "/home",
                    title: "首页",
                    active: require("@/assets/home/icon-首页@2x.png"),
                    normal: require("@/assets/home/icon-首页（灰）.png")
                },
                {
                    name: "/file",
                    title: "文档",
                    active: require("@/assets/home/icon-文档@2x.png"),
                    normal: require("@/assets/home/icon-文档（灰）@2x.png")
                },
                {
                    name: "/sign",
                    title: "签到",
                    active: require("@/assets/home/icon-签到@2x.png"),
                    normal: require("@/assets/home/icon-签到（灰）@2x.png")
                },
                {
                    name: "/user",
                    title: "我的",
                    active: require("@/assets/home/icon-我的(未选中）@2x.png"),
                    normal: require("@/assets/home/icon-我的(灰）@2x.png")
                    
                },
            ]
        }
    },
 
    computed:{
        active:{
            get(){
                return this.$route.meta.activeNum
            },
            set(){}
        }
    }
}
</script>