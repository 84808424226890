<template>
  <div class="upload">
    <input type="file" multiple @change="handleFileChange" ref="fileInput" accept=".pdf,.doc,.docx"/>
    <button @click="handleUpload">上传</button>
  </div>
</template>
  
  <script>
import api from "@/request/api";
import log from "@/request/log";
import {
  Toast
} from "vant";
export default {
  props: ["checked"],

  data() {
    return {
      fileList: [], // 存储待上传的文件列表
      uploadedList: [], // 存储已上传成功的文件列表
    };
  },
  methods: {
    upload() {
      const token = localStorage.getItem("token");
      console.log("token", token);
      if(token==null){
        this.getAccessTokens();
      }

      

      log.info({
        p: "h",
        a: "m",
      });
      this.$refs.fileInput.click();
    },
    getAccessTokens() {
      api
        .getAccessTokens()
        .then((res) => {
          localStorage.setItem("token", res.token);
        })
        .catch((err) => {
          log.error({
            p: "h",
            a: "e",
          });
        });
    },
    handleFileChange(event) {
      console.log(event)
      this.fileList = Array.from(event.target.files);
      if (this.fileList.length === 0) {
        this.$toast("请选择要上传的文件");
        return;
      } else {
        const allowFormats = ["docx", "pdf"];
        for (let i = 0; i < this.fileList.length; i++) {
          const extension = this.fileList[i].name.split(".").pop();
          if (!allowFormats.includes(extension)) {
            this.$toast("文件格式不正确");
            return;
          }
        }
        this.handleUpload();
      }
    },
    // 批量上传文件
    async handleUpload() {
      log.info({
        p: "h",
        a: "start",
      });
      // 校验是否选择了文件
      // 遍历待上传的文件列表，逐个上传
      for (const file of this.fileList) {
        try {
          // 构造FormData实例，用于传递文件数据和签名信息给后端
          const formData = new FormData();
          formData.append("memo", file.name); //accessKeyId
          formData.append("size", file.size); //policy
          formData.append("fileType", file.name.split(".").pop()); //expire
          formData.append("file", file); //签名
          const token = localStorage.getItem("token");
          // 调用后端签名接口，获取签名信息
          await api.tablePump(token, formData).then((res) => {
            if(res.ocrStatus===-1){
              this.$toast('识别失败');
              return;
            }
            this.$toast('上传成功');
            // 获取 Excel 文件的下载链接
            const result = JSON.parse(res.result);
            const baseUrl = process.env.VUE_APP_API_URL;
            console.log("跟路径 ",baseUrl)
            //const downloadUrl = result.outputFile.filename;
            const downloadUrl = `${baseUrl}open-api/table-pump/download?token=${token}&filepath=${result.outputFile.filename}`;

            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = "example.xlsx";

            document.body.appendChild(a);
            a.click();
            this.$toast('下载成功');
            document.body.removeChild(a);
           
          });
        } catch (e) {
          this.$toast('识别失败');
          log.error({
            p: "h",
            a: "e",
          });
          console.error(e);
        }
      }
      // 清空待上传的文件列表
      log.info({
        p: "h",
        a: "end",
      });
      this.fileList = [];
      this.uploadedList = [];
    },
  },
};
</script>
  
  <style lang="less" scoped>
.upload {
  display: none;
}
</style>