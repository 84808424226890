import Desktop from "@/components/Desktop";
import Upload from "@/components/Upload";
import Tablepump from "@/components/Tablepump";
import api, {
  CreateUser,
  getUser
} from "@/request/api";
import log from "@/request/log";
import {
  Dialog,
  Toast
} from "vant";

export default {
  name: "Home",
  data() {
    return {
      fileList: [], // 存储待上传的文件列表
      uploadedList: [], // 存储已上传成功的文件列表
      showPopup: false, //微信浏览器   
      ticket: "",
      pageNo: 1,
      pageSize: 2,
      radio: [],
      rows: [],
      imageEWM: require("@/assets/home/二维码.jpg"),
      imageURL: require("@/assets/home/banner@2x.png"),
      imageOCR: require("@/assets/home/文字识别@2x.png"),
      imageZH: require("@/assets/home/格式转换@2x.png"),
      imageNum: require("@/assets/home/icon-图@2x.png"),
      imageClose: require("@/assets/close-round.svg"),
      imageEye: require("@/assets/eye.svg"),
      imageEyeClose: require("@/assets/eye-close.svg"),
      SearchVal: "",
      checked: false,
      isShowModel: false,
      activeKey: 0,
      showPopover: false,
      // show no trace notice switch.
      showNoTraceNotice: true,
      // 通过 actions 属性来定义菜单选项
      actions: [{
          text: "添加到桌面",
          icon: require("@/assets/home/icon-添加到桌面@2x.png")
        },
        {
          text: "访问pc端",
          icon: require("@/assets/home/icon-访问pc端@2x.png")
        },
        // {
        //   text: "下载APP",
        //   icon: require("@/assets/home/icon-下载APP@2x.png")
        // },
        // {
        //   text: "微信小程序",
        //   icon: require("@/assets/home/icon-微信小程序@2x.png")
        // },
      ],
      sheetActions: [
        // {
        // name: '拍照上传'
        // }, 
        {
          name: '相册上传'
        }, {
          name: '电脑上传'
        },
        //  {
        //   name: '微信发送'
        // }
      ],
      bgtqsheetActions:[
        {
          name: '文件上传'
        }, {
          name: '电脑上传'
        },
      ],
      number: "",
      show: false,
      sheetISShow: false,
      bgtqsheet: false,
      brand: "",
      signBtnShow: true,
      startTime: null,
    };
  },
  created() {
    this.createUser()
    this.getUser()
    this.getBrand()
  },
  mounted() {
    log.info({
      p: "h",
      a: "m",
    });
    // 记录页面打开时间
    this.startTime = new Date()
    this.getList()
  },
  beforeDestroy() {
    // 计算页面停留时间并记录到后端
    const endTime = new Date()
    const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
    log.info({
      p: "h",
      a: "u",
      d: stayTime
    });
  },
 
  methods: {
    //
    onClickRight() {
      this.showPopover = true
    },
    //获取手机品牌
    getBrand() {
      const userAgentInfo = navigator.userAgent.toLowerCase()
      const agents = ["vivo", "huawei", "oppo", "xiaomi", "iphone"]
      for (let i = 0; i < agents.length; i++) {
        if (userAgentInfo.indexOf(agents[i]) > 0) {
          this.brand = agents[i]
          localStorage.setItem("brand",this.brand)
          break
        }
      }
    },
    //获取设备唯一id
    getDeviceId() {
      return navigator.userAgent
    },
    gszh() {
      Toast('即将提供，敬请期待')
    },
    bgtq(){
      this.bgtqsheet=true;
    },
    bgtqselectSheet(number) {
      log.info({
        p: "h",
        a: "c",
        o: "bgtqselectSheet",
      });
      if (number.name == "文件上传") {
        this.$refs.tablepump.upload();
      } else if (number.name == "电脑上传") {
        Dialog.alert({
          title: "温馨提示",
          message: "请用电脑访问: filequan.com传文件更容易",
        }).then(() => {
          // on close
        });
      }
    },

    isckecked() {
      //无痕模式切换
      this.checked = !this.checked
      localStorage.setItem("noTraceMode", this.checked);
    },
    onSelect(number) {
      log.info({
        p: "h",
        a: "c",
        o: "popover"
      });
      if ((number.text == "访问pc端")) {
        Dialog.alert({
          title: "温馨提示",
          message: "请用电脑访问: filequan.com传文件更容易",
        }).then(() => {
          // on close
        });
      } else if (number.text == "微信小程序") {
        this.show = true;
      } else if (number.text == "添加到桌面") {
        localStorage.setItem("addToDesktop", "true")

        function isWechatBrowser() {
          const ua = window.navigator.userAgent.toLowerCase();
          return ua.indexOf('micromessenger') !== -1;
        }
        // 调用方法
        if (isWechatBrowser()) {
          this.$refs.desktop.weixinOpen()
        } else {
          this.$refs.desktop.open()
        }
      }
    },
    handleClose() {
      this.showPopup = false
    },
    wjsc() {
      this.sheetISShow = true;
    },
    selectSheet(number) {
      log.info({
        p: "h",
        a: "c",
        o: "actionsheet",
      });
      if (number.name == "相册上传") {
        this.$refs.ocr.$refs.fileInput.click();
      } else if (number.name == "电脑上传") {
        Dialog.alert({
          title: "温馨提示",
          message: "请用电脑访问: filequan.com传文件更容易",
        }).then(() => {
          // on close
        });
      }
    },
    // hide no trace notice.
    hideNoTraceNotice() {
      this.showNoTraceNotice = false;
    },
    // hide or show no trace notice.
    hideOrShowNotice() {
      this.showNoTraceNotice = !this.showNoTraceNotice;
    },
    //最近文档展示 
    getList() {
      api.GetFileList({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        //console.log(res)
        for (let i = 0; i < res.content.length; i++) {
          let date = new Date(res.content[i].createdAt);
          let date2 = date.toLocaleDateString().replace(/\//g, "/") + " " + date.toTimeString().substring(0, 5);
          res.content[i].createdAt = date2
        }
        this.rows = res.content
      }).catch(err=>{
        log.error({
          p: "h",
          a: "m",
        })
      })
    },
    //获取临时用户
    createUser() {
      //若设备id不存在，则创建用户
      // const deviceId = this.getDeviceId();
      // if (localStorage.getItem("deviceId")) {
      //   return
      // } else {
        CreateUser({
            //deviceId: deviceId
          
        }).then(res => {
          localStorage.setItem("ticket", res.ticket);
          localStorage.setItem("deviceId", deviceId);
        }).catch(err => {
          log.error({
            p: "h",
            a: "m",
          })
        })
     // }
    },

    //获取用户资料
    getUser() {
      getUser().then(res => {
        this.signBtnShow = !res.todaySigned
      }).catch(err=>{
        log.error({
          p: "h",
          a: "m",
        })
      })
    },
    sign() {
      log.info({
        p: "h",
        a: "c",
        o: "signbtn"
      });
      this.$router.push('/sign')
    },
  },
  components: {
    Desktop,
    Upload,
    Tablepump
  }

}