import request from "./request"
export default {
    //创建OCR任务文件
    CreateOcr(data) {
        return request({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `/aliyun/oss/uploadPolicy`,
            method: 'post',
            data
        })
    },
    //查询文档列表
    GetFileList(params) {
        return request({
            url: `/ocr/files/`,
            method: 'get',
            params
        })
    },
    //查询OCR任务状态
    SelectOcrStatus(id) {
        return request({
            url: `/ocr/file/${id}`,
            method: 'get',
        })
    },
    // 4. OCR任务文件切换无痕模式（多文件）
    SelectOcrFilesStatus(ids, params) {
        return request({
            url: `/ocr/files/${ids}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'put',
            params
        })
    },
    //多文件OCR结果打包
    GetOcrFiles(content) {
        return request({
            url: `/ocr/files/${content}`,
            method: 'get',
        })
    },
    //获取短信信息
    smscode(data) {
        return request({
            url: `/smsCode`,
            method: 'post',
            data
        })
    },
    //通过手机号码注册或登录
    login(phoneNumber, code) {
        return request({
            headers: {
                "phoneNumber": `${phoneNumber}`,
                "verifyCode": `${code}`
            },
            url: `/user`,
            method: 'get',

        })
    },
    //修改用户信息
    updateUser(params){
        return request({
            url: `/user-resource/profile`,
            method: 'put',
            params
        })
    },
    //修改备注
    updateDetail(id,data){
        return request({
            url: `user-resource/file/${id}/detail`,
            method: 'put',
            data
        })
    },
    //批量删除
    deleteFile(ids){
        return request({
            url: `ocr/files/${ids}`,
            method: 'delete',
        })
    },
    getAccessTokens(){
        return request({
            url: `user-resource/accessToken`,
            method: 'post',
        })
    },
    //表格提取
    tablePump(token,data){
        return request({
            url: `open-api/table-pump?token=${token}`,
            method: 'post',
            data
        })
    },
    //下载
    downloadExcel(token,filepath){
        return request({
            url: `open-api/table-pump/download?token=${token}&filepath=${filepath}`,
            method: 'post',
        })
    },

    //获取微信接口
    sharenOnceStr(params){
        return request({
            url: `getJsapiTicket`,
            method: 'get',
            params
          
        })
    }
    
    
}
//创建临时用户身份
export const CreateUser = params => request.post("/user", params)

//获取用户积分
export const Getaccount = () => request.get("/user-resource/account")

export const signIn = params => request.post("/user-resource/signIn", params)

export const gettingAward = params => request.post("/user-resource/gettingAward", params)

//12. 获取用户资料
export const getUser = () => request.get("/user-resource/profile")

//获得我的标签和建议标签
export const getTags = () => request.get("/user-resource/tags")
//获取阿里云oss密钥
export const getAliyunOSS = () => request.get("/aliyun/oss")
