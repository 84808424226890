import {
    getUser
} from "@/request/api"
import api from "@/request/api"
import log from "@/request/log"
import wx from 'weixin-js-sdk';
import axios from 'axios';
import Clipboard from 'clipboard'
import {
    Dialog,
    Toast
} from 'vant';
import draggable from "vuedraggable";
export default {
    data() {
        return {
            openId: "",
            files: [],
            currentIndex: 0,
            show: false,
            content: [],
            pollCount: 0
        }
    },

    mounted() {
        log.info({
            p: "u",
            a: "m",
        });
        // 记录页面打开时间
        this.getOpenId();
        this.upload();
    },

    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
            p: "u",
            a: "u",
            d: stayTime
        });
    },
    components: {
        Dialog,
        draggable

    },
    computed: {

    },



    methods: {
        change(event) {
            console.log("change", event);
        },
        start(event) {
            console.log("start", event);
        },
        end(event) {
            console.log("end", event, this.files);
        },
        move(event, orgin) {
            console.log("move", event, orgin);
        },


        getOpenId() {
            this.openId = this.$route.query.openId
        },
        upload() {
            this.$refs.fileInput.click();
        },

        handleInputClick(event) {
            const files = Array.from(event.target.files);
            console.log("333333333333", files)
            this.handleUpload(files);
        },
        handleUpload(files) {

            const disallowedTypes = [
                "doc",
                "docx",
                "xls",
                "xlsx",
                "wps",
                "ppt",
                "pptx",
                "jpg",
                "jpeg",
                "tif",
                "png",
                "ofd",
                "html",
            ];
            // 存储不允许上传的文件后缀
            const disallowedExtensions = [];
            if (files.length == undefined) {
                // let img;
                // if (files.type.includes("jpg")) {
                //     img = require('@/assets/ocr/jpg.png');
                //     alert(1)
                // } else if (files.type.includes("tif")) {
                //     img = require('@/assets/small/tiff.png');
                // } else if (files.type.includes("doc")) {
                //     img = require('@/assets/small/doc.png');
                // } else if (files.type.includes("ppt")) {
                //     img = require('@/assets/small/ppt.png');
                // }

                const fileExtension = files.name.split(".").pop().toLowerCase();
                // 判断文件扩展名是否在不允许上传的文件类型数组中
                if (disallowedTypes.indexOf(fileExtension) == -1) {
                    // 文件类型不允许，阻止上传
                    this.$toast(`暂不支持上传${fileExtension}文件格式`);
                    return false;
                }

                // this.files.push({
                //     name: files.name, // 文件名
                //     type: files.type, // 文件类型
                //     size: files.size, // 文件大小等
                //     img: img
                // });
            } else {
                for (let i = 0; i < files.length; i++) {
                    // 将文件信息添加到数组中，可以根据需要自定义文件对象的属性

                    const list = files[i];

                    // 获取文件的扩展名
                    const fileExtension = list.name.split(".").pop().toLowerCase();
                    // 判断文件扩展名是否在不允许上传的文件类型数组中
                    if (disallowedTypes.indexOf(fileExtension) == -1) {
                        // 文件类型不允许，阻止上传
                        disallowedExtensions.push(fileExtension);
                    }


                    // this.files.push({
                    //     name: list.name, // 文件名
                    //     type: list.type, // 文件类型
                    //     size: list.size, // 文件大小等
                    // });

                    // this.files.forEach((item) => {

                    //     if (item.type.includes("jpg") || item.type.includes("jpeg") || item.type.includes("png")) {
                    //         item.img = require('@/assets/ocr/jpg.png');
                    //     } else if (item.type.includes("tif")) {
                    //         item.img = require('@/assets/small/tiff.png');
                    //     } else if (item.type.includes("doc")) {
                    //         item.img = require('@/assets/small/doc.png');
                    //     } else if (item.type.includes("ppt")) {
                    //         item.img = require('@/assets/small/ppt.png');
                    //     }
                    // })


                }
                // 判断是否有不允许上传的文件后缀
                if (disallowedExtensions.length > 0) {
                    // 将不允许上传的文件后缀显示出来
                    const disallowedExtensionsString = disallowedExtensions.join(", ");
                    this.$toast(`暂不支持上传${disallowedExtensionsString}文件格式`);
                    return false;
                }

            }
            const formData = new FormData();
            formData.append("openId", this.openId);
            if (files.length == undefined) {
                formData.append("files", files);
            } else {
                for (let i = 0; i < files.length; i++) {
                    formData.append("files", files[i]);
                }
            }
            const baseUrl = process.env.VUE_APP_API_URL;
            axios
                .post(`${baseUrl}fileToPdf`, formData, {
                    headers: {
                        apiVersion: "0.1.0",
                    },
                })
                .then((response) => {
                    this.show = true;
                    this.queryById(response.data.id);
                })
                .catch((error) => {
                    console.error(error);
                    // 处理上传失败的代码
                    this.$toast("上传失败");
                });
        },


        // getFileList(id) {
        //     this.queryById(id);
        //     console.log("结果",this.content)
        //     if (this.content.convertStatus === 0) {
        //         // 如果status等于0，继续轮询
        //         setTimeout(() => {
        //             this.getFileList(id);
        //         }, 1000); // 每隔1秒执行一次轮询
        //     } else {
        //         // 轮询停止后执行特定操作
        //         console.log('轮询已停止');
        //         this.show = false;
        //         //其他页面你可以使用
        //         wx.miniProgram.navigateTo({url: '/path/to/page'})
        //     }
        // },

        checkStatus(convertStatus,id) {
            let hasLoadingData = false;
            if(convertStatus == 0){
                hasLoadingData = true;
            }
          
            if (hasLoadingData) {
              if (this.pollCount < 50) {
                // 检查轮询次数
                this.pollCount++; // 轮询次数加一
                this.timer = setTimeout(() => {
                  this.queryById(id);
                }, 1000);
              } else {
                clearTimeout(this.timer); // 停止轮询
                if (this.uploadStatus) {
                  message.error("转换pdf失败,已超时...");
                }
              }
            } else {
              clearTimeout(this.timer);
              this.show =false;
              wx.miniProgram.navigateTo({url: '/pages/file/file?id='+id})
            }
          },

        queryById(id) {
            const baseUrl = process.env.VUE_APP_API_URL;
            axios.get(`${baseUrl}pdf/${id}`, {
                params: {
                    openId: this.openId
                  }
                })
                .then((response) => {
                    this.show = true
                    const convertStatus = response.data.convertStatus
                    const id = response.data.id
                    console.log("c",convertStatus);
    
                    this.checkStatus(convertStatus,id);
                })
                .catch((error) => {
                    console.error(error);
                    // 处理上传失败的代码

                    this.$toast("上传失败");
                });
        }
    }


}