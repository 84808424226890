import api from "@/request/api"
import log from "@/request/log"
import {
    Dialog, Toast
} from "vant";
export default {
    data() {
        return {
            startTime: null,
            comment: "", //备注
            filePopup: false,
            showFields: false,
            pageNo: 1,
            pageSize: 2,
            summary: "",
            visible: false,
            content: [],
            isShow: false,
            msg: "",
            textareaISshow: false,
            sheetISShow: false,
            SearchVal: "",
            showPopover: false,
            show: false,
            actions: [{
                    text: "编辑文档",
                    icon: require("@/assets/file/icon-编辑1@2x.png")
                },
                {
                    text: "扩容",
                    icon: require("@/assets/file/icon-扩容@2x.png")
                },
            ],
            ids:[],
            activeName: 'a',
            rows: [],
            result: [],
            sheetActions: [{
                    name: '复制'
                },
                {
                    name: '朗读'
                },
                {
                    name: '多选'
                }
            ],
        }
    },
    methods: {
        
        //编辑文档
        updateGood(item) {     
            log.info({
                p: "f",
                a: "c",
                o: "updateBtn"
              });    
            this.$router.push({
                name: 'TagFile',
                query: {
                    file: JSON.stringify(item)
                  }
            })
        },
        //显示文本框
           //显示文本框
           showField(id) {    
            log.info({
                p: "f",
                a: "c",
                o: "showFiled"
              }); 
             this.showFields =id
        },
        //获取识别失败结果
        getErr() {
            this.rows.map(item => {
                if (item.ocrStatus==0) { // 如果不存在name属性
                    item.summary = '等待识别'; // 给item加上name属性并赋值
                }else if(item.ocrStatus==-1){
                    item.summary = '识别失败';
                }
                return item; // 返回修改后的item
            });
        },
        //最近文档展示
        getList() {
            api.GetFileList().then(res => {
               
                for (let i = 0; i < res.content.length; i++) {
                    let date = new Date(res.content[i].createdAt);
                    let date2 = date.toLocaleDateString().replace(/\//g, "/") + " " + date.toTimeString().substring(0, 5);
                    if (res.content[i].summary) {
                        res.content[i].summary = res.content[i].summary.replaceAll("\n", '');
                    }
                    if (res.content[i].comment == null) {
                        res.content[i].comment = res.content[i].fileName
                    }
                    res.content[i].createdAt = date2
                }
                this.rows = res.content
                this.getErr()
            }).catch(err => {
                log.error({
                    p: "f",
                    a: "e",
                  });
                console.log("err", err)
            })
        },
        onSelect(number) {
            log.info({
                p: "f",
                a: "c",
              }); 
            if ((number.text == "编辑文档")) {
                this.$router.push("/editFile")
            }
        },
        top(item) {
            log.info({
                p: "f",
                a: "c",
                o: "topBtn"
              }); 
            const index = this.rows.indexOf(item)
              this.rows.splice(index, 1)
              this.rows.unshift(item)
              this.rows.forEach((i) => {
                i.top = false
              })
                item.top = true
        },
        delGood(item) {
            log.info({
                p: "f",
                a: "c",
                o: "delBtn"
              }); 
            Dialog.confirm({
                    title: '温馨提示',
                    message: '确定要删除文件'+item.comment+'及OCR识别结果吗?',
                })
                .then(() => {
                    this.ids.push(item.id)
                    // on confirm
                      api.deleteFile(
                         this.ids
                      ).then(res=>{
                          if(res.state=="true"){
                              Toast("删除成功！")
                              this.getList()
                              this.ids=[]
                          }else{
                             Toast("删除失败！")
                             this.ids=[]
                          }

                      })
                }).catch(err=>{
                    log.error({
                        p: "f",
                        a: "e",
                      });
                })
                .catch(() => {});
        },
        start() {
            // clearTimeout(this.loop); //再次清空定时器，防止重复注册定时器
            // this.loop = setTimeout(() => {
            //     this.sheetISShow = true
            //     console.log("长按了");
            // }, 1000);
        },
        end() {
            // clearTimeout(this.loop); //清空定时器，防止重复注册定时器
        },
    },
    created() {
        this.getList()
    },
    mounted() {
        log.info({
            p: "f",
            a: "m",
          });
          // 记录页面打开时间
          this.startTime = new Date()
    },
    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
          p: "f",
          a: "u",
          d: stayTime
        });
      },
}