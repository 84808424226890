import log from "@/request/log"
import wx from 'weixin-js-sdk';
import axios from 'axios';
import {
    Dialog,
    Toast
} from 'vant';
export default {
    data() {
        return {
            ticket: "",
            files: [],
            currentIndex: 0,
            show: false,
            content: [],
            pollCount: 0
        }
    },

    mounted() {
        log.info({
            p: "u",
            a: "m",
        });
        // 记录页面打开时间
        this.getTicket();
        this.upload();
    },

    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
            p: "u",
            a: "u",
            d: stayTime
        });
    },
    methods: {
        getTicket() {
            this.ticket = this.$route.query.ticket
        },
        upload() {
            this.$refs.fileInput.click();
        },

        handleInputClick(event) {
            const files = Array.from(event.target.files);
            this.handleUpload(files);
        },
        handleUpload(files) {

            const disallowedTypes = [
                "jpg",
                "jpeg",
                "pdf",
                "png",
                "ofd",
                "xml"
            ];
            // 存储不允许上传的文件后缀
            const disallowedExtensions = [];
            if (files.length == undefined) {
                const fileExtension = files.name.split(".").pop().toLowerCase();
                // 判断文件扩展名是否在不允许上传的文件类型数组中
                if (disallowedTypes.indexOf(fileExtension) == -1) {
                    // 文件类型不允许，阻止上传
                    this.$toast(`暂不支持上传${fileExtension}文件格式`);
                    return false;
                }

            } else {
                for (let i = 0; i < files.length; i++) {
                    // 将文件信息添加到数组中，可以根据需要自定义文件对象的属性
                    const list = files[i];
                    // 获取文件的扩展名
                    const fileExtension = list.name.split(".").pop().toLowerCase();
                    if (disallowedTypes.indexOf(fileExtension) == -1) {
                        disallowedExtensions.push(fileExtension);
                    }

                }
                if (disallowedExtensions.length > 0) {
                    const disallowedExtensionsString = disallowedExtensions.join(", ");
                    this.$toast(`暂不支持上传${disallowedExtensionsString}文件格式`);
                    return;
                }
            }
            const formData = new FormData();
            if (files.length == undefined) {
                formData.append("file", files);
            } else {
                for (let i = 0; i < files.length; i++) {
                    formData.append("file", files[i]);
                }
            }
            const baseUrl = "https://spb-api.filequan.com/";
            axios
                .post(`${baseUrl}bill/file/localUpload`, formData, {
                    headers: {
                        ticket: this.ticket,
                        apiVersion: "0.1.0",
                    },
                })
                .then((response) => {
                    this.$toast("上传成功")
                    setTimeout(() => {
                       wx.miniProgram.switchTab({url: '/pages/list/list'});
                    }, 1000);
                })
                .catch((error) => {
                    console.error(error);
                    // 处理上传失败的代码
                    this.$toast("上传失败");
                    setTimeout(() => {
                        wx.miniProgram.switchTab({url: '/pages/list/list'});
                    }, 1000);
                });
        },
    }
}