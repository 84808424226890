<template>
  <div class="upload">
    <input
      type="file"
      multiple
      id="files"
      @change="handleInputClick"
      ref="fileInput"
      accept="image/*"
    />
    <button @click="handleUpload">上传</button>
  </div>
</template>

<script>
import api from "@/request/api";
import log from "@/request/log";
import { gettingAward } from "@/request/api";
import axios from 'axios';
import qs from "qs";
export default {
  props: ["checked"],

  data() {
    return {
      fileList: [], // 存储待上传的文件列表
      uploadedList: [], // 存储已上传成功的文件列表
      ids: [],
    };
  },
  created() {},
  methods: {
    handleInputClick(event) {
      const files = Array.from(event.target.files);

      this.handleUpload(files);
    },
    handleUpload(files) {
      const disallowedTypes = ["jpg", "tif", "png", "pdf","jpeg"];
      // 存储不允许上传的文件后缀
      const disallowedExtensions = [];
      if (files.length == undefined) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        // 判断文件扩展名是否在不允许上传的文件类型数组中
        if (disallowedTypes.indexOf(fileExtension) == -1) {
          // 文件类型不允许，阻止上传
          this.$toast(`暂不支持上传${fileExtension}文件格式`);
          return false;
        }
      } else {
        for (let i = 0; i < files.length; i++) {
          const list = files[i];
          // 获取文件的扩展名
          const fileExtension = list.name.split(".").pop().toLowerCase();
          // 判断文件扩展名是否在不允许上传的文件类型数组中
          if (disallowedTypes.indexOf(fileExtension) == -1) {
            // 文件类型不允许，阻止上传
            disallowedExtensions.push(fileExtension);
          }
        }

        // 判断是否有不允许上传的文件后缀
        if (disallowedExtensions.length > 0) {
          // 将不允许上传的文件后缀显示出来
          const disallowedExtensionsString = disallowedExtensions.join(", ");
          this.$toast(`暂不支持上传${disallowedExtensionsString}文件格式`);
          return false;
        }
      }
      const formData = new FormData();
      if (files.length == undefined) {
        formData.append("file", files);
      } else {
        for (let i = 0; i < files.length; i++) {
          formData.append("file", files[i]);
        }
      }
    
      const baseUrl = process.env.VUE_APP_API_URL;

      const ticket = localStorage.getItem("ticket");

      axios
        .post(`${baseUrl}ocr/file/upload`, formData, {
          headers: {
            apiVersion: "0.1.0",
            ticket: ticket,
          },
        })
        .then((response) => {
          let ids = "";
 
          if(response.data.length > 1){
             ids = response.data.join(',')
          }else{
             ids = response.data.join('')
          }

       
          // 处理上传成功的代码

          this.$router.push({
              path: '/ocrPopup',
              query: {
                ids: ids
              }
            })
        })
        .catch((error) => {
          console.error(error);
          // 处理上传失败的代码

          this.$toast("上传失败");
        });
    },
  },
};
</script>

<style lang="less" scoped>
// .upload {
//   display: none;
// }
</style>