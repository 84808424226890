/**
 * @description: log request, powered by aliyun log service.
 */
 import axios from "axios";

 const request = axios.create({
   baseURL: `https://oss-log-1289076259473456-cn-beijing.cn-beijing.log.aliyuncs.com/logstores/fiq/track?APIVersion=0.6.0&e=${process.env.VUE_APP_LOG_ENV}&s=m`,
   timeout: 5000,
 });
 
 const log = {
   info(record){
     this.log(record, "i");
   },
   warn(record){
     this.log(record, "w");
   },
   error(record){
    this.log(record, "e");
  },
   log(record, level){
     if (!record) {
       throw new Error("record is required.");
     }
     if (!level) {
       throw new Error("level is required.");
     }
     record.l = level;
     // info level log.
     request({
       url: "",
       method: "get",
       params: record,
     });
   },
 };
 export default log;