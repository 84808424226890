import { render, staticRenderFns } from "./TagFile.vue?vue&type=template&id=c2dd6730&scoped=true"
import script from "./tagFile?vue&type=script&lang=js&external"
export * from "./tagFile?vue&type=script&lang=js&external"
import style0 from "./TagFile.vue?vue&type=style&index=0&id=c2dd6730&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2dd6730",
  null
  
)

export default component.exports