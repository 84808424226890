<template>
  <div id="app">
   
    
    <router-view></router-view>

 
    <AppTabbar v-show="$route.meta.isShowTabbar"/>
    <div style="height:.5rem"></div>
  </div>
</template>




<script>

import AppTabbar from "@/components/AppTabbar";

export default {
    components:{
      AppTabbar
    }
}
window.onload = function() {
			document.addEventListener('touchstart', function(event) {
				if (event.touches.length > 1) {
					event.preventDefault()
				}
			})
			document.addEventListener('gesturestart', function(event) {
				event.preventDefault()
			})
      	}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(245,246,250);
  min-height:100%;
  font-size: .14rem;
}
body, html{
  height:100%;
}
html{
  font-size:100px!important;  

}

</style>
