import { render, staticRenderFns } from "./Share.vue?vue&type=template&id=3ad0fcfc&scoped=true"
import script from "./Share?vue&type=script&lang=js&external"
export * from "./Share?vue&type=script&lang=js&external"
import style0 from "./Share.vue?vue&type=style&index=0&id=3ad0fcfc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad0fcfc",
  null
  
)

export default component.exports