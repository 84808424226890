import api from "@/request/api"
import log from "@/request/log"
import axios from 'axios';
import {
    getAliyunOSS
} from "@/request/api"
import OSS from 'ali-oss';
import Clipboard from 'clipboard'
import {
    Toast
} from "vant";
import NativeShare from 'nativeshare'
export default {
    data() {
        return {
            synthesizer: null,
            timer: null,
            showImage: false,
            visible: false,
            isFullscreen: false,
            fullscreenContainer: null,
            content: [],
            isSpeaking: false,
            speech: null,
            pageNo: 1,
            pageSize: 4,
            ids: [],
            total: "",
            totalPages: "",
            a1: require('@/assets/ocr/icon-无痕@2x.png'),
            a2: require('@/assets/ocr/icon-退出无痕@2x.png'),
            noTraceMode: false,
            message: "",
            fileName: "",
            fileType: "",
            fileId: "",
            filePath: "",
            path: "",
            prompts: "TXT为纯文本格式,体积小易于发送",
            current: 0,
            show: true,
            button_data: [{
                    name: 'TXT',
                    message: "TXT为纯文本格式,体积小易于发送"
                },
                {
                    name: '双层PDF',
                    message: "双层PDF保留源图像文件并附带文本层既保持视觉原貌又可检索、复制文本"
                },
                {
                    name: '文字PDF',
                    message: "文字PDF为识别结果遵照源图片段落格式保存,可检索、复制文本"
                },
            ],
            swipe: [],
            active: -1,
            currentitemId: 0,
            startTime: null
        }

    },
    created() {
        this.init()
    },
    mounted() {
        log.info({
            p: "o",
            a: "m",
        });
        // 记录页面打开时间
        this.startTime = new Date()
        // 初始化
        // 初始化 Web Speech API
        this.synthesizer = window.speechSynthesis;
    },
    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
            p: "o",
            a: "u",
            d: stayTime
        });
    },
    methods: {
        handleDoubleClick() {
            log.info({
                p: "o",
                a: "c",
            });
            this.enterFullscreen()
        },
        closeDoubleClick() {
            log.info({
                p: "o",
                a: "c",
            });
            this.exitFullscreen()
        },

        onShareSelect(share) {
            if (share.name = "QQ") {
                this.call('qZone')
            }
        },
        onShareCancel() {
            console.log('取消分享');
        },
        //保存
        saveText() {
            log.info({
                p: "o",
                a: "c",
                o: "saveBtn"
            });
            if (this.filePath == "" || this.fileName == "") {
                Toast('请选择要保存的文件')
                return
            }
            let filePath = "";
            if (this.current == 0) {
                filePath = this.filePath + this.fileName + ".txt"
                // this.getAliyunOSS(path)
            } else if (this.current == 1) {
                filePath = this.filePath + this.fileName + "_d.pdf"

            } else {
                filePath = this.filePath + this.fileName + ".pdf"
            }
            this.downloadFile(filePath);

        },

        //下载全部
        saveAll() {
            log.info({
                p: "o",
                a: "c",
                o: "saveAllBtn"
            });
            // api.GetOcrFiles(
            //     this.ids
            // ).then(res => {
            //     // console.log(res)
            //     this.path = res.path
            //     this.getAliyunOSS(this.path)
            //     Toast.success('下载成功');
            // }).catch(err => {
            //     log.error({
            //         p: "o",
            //         a: "e",
            //     });
            //     Toast.fail('下载失败');
            // })
        
    
            if (this.current == 0) {
                this.downloadAllFiles("txt");
            } else if (this.current == 1) {
                this.downloadAllFiles("d-pdf")

            } else {
                this.downloadAllFiles("pdf")
            }
        
        },
        downloadAllFiles(type){
            const baseUrl = process.env.VUE_APP_API_URL;

            const ticket = localStorage.getItem("ticket");
            const formData = new FormData();
      
            formData.append("type", type);
            axios
            .post(`${baseUrl}ocr/file/batchDownload/${this.ids}`, formData, {
              headers: {
                apiVersion: "0.1.0",
                ticket: ticket,
              },
            })
            .then((response) => {
              
    
                const path = response.data.filePath;
                console.log("下载全部",path)
                const a = document.createElement("a");
                a.href = `${baseUrl}ocr-convert/common/download?filepath=${path}`;
                //a.download = `${file[0].memo}.pdf`;
                a.click();
                this.$toast("下载成功");
             
            })
            .catch((error) => {
              console.error(error);
              // 处理上传失败的代码
    
              this.$toast("下载失败");
            });
        },
    
        checkStatus() {
            let hasLoadingData = false
            for (let i = 0; i < this.content.length; i++) {
                if (this.content[i].ocrStatus === 0) {
                    hasLoadingData = true
                }
            }
            if (hasLoadingData) {
                this.timer = setTimeout(() => {
                    this.init()
                }, 1000)
            } else {
                clearTimeout(this.timer)
            }
        },
        init() {
            this.content = []
            const ids = this.$route.query.ids.split(",")
            this.ids = ids
            api.GetFileList({
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then(res => {
                for (let i = 0; i < res.content.length; i++) {
                    for (let j = 0; j < this.ids.length; j++) {
                        if (res.content[i].id == this.ids[j]) {
                            this.content.push(res.content[i]);
                        }
                    }
                }
                this.content.forEach((item) => {
                    if (item.fileType == "jpg" || item.fileType == "jpeg") {
                        item.img = require('@/assets/ocr/jpg.png')
                    } else if (item.fileType == "png") {
                        item.img = require('@/assets/ocr/png.png')
                    }
                })
                this.message = this.content[0].summary
                this.swipe = []
                const totalPages = Math.ceil(this.ids.length / this.pageSize);
                for (let i = 0; i < totalPages; i++) {
                    this.swipe.push(i)
                }
                this.checkStatus()
            }).catch(err => {
                log.error({
                    p: "o",
                    a: "e",
                });
            })
        },
        //  getAliyunOSS(path) {
        //     getAliyunOSS().then(res => {
        //         console.log(res)
        //         // 解析从后端返回的数据，得到OSS密钥及文件路径
        //         var client = new OSS({
        //             accessKeyId: res.accessKeyId,
        //             accessKeySecret: res.accessKeySecret,
        //             region: res.region,
        //             bucket: res.bucket, // 你的BucketName
        //             host: "https://" + res.bucket + "." + res.region + "." + "aliyuncs.com"

        //         });
        //         // 生成签名URL
        //         const url = client.signatureUrl(path, {
        //             expires: 3600
        //         });
        //         //返回签名URL给前端使用
        //         let a = document.createElement("a");
        //         a.href = url;
        //         a.download = path
        //         a.click()
        //     }).catch(err=>{
        //         log.error({
        //             p: "o",
        //             a: "e",
        //           });
        //     })
        // },
        downloadFile(path) {
            const baseUrl = process.env.VUE_APP_API_URL;
            const link = document.createElement("a");
            link.href = `${baseUrl}ocr-convert/common/download?filepath=${path}`;
            link.click();
            this.$toast("下载成功");
        },
        enterFullscreen() {
            log.info({
                a: "c",
                p: "o",
                o: "enterFullscreenBtn"
            })
            this.isFullscreen = true;
            const field = document.getElementById("field")
        },
        exitFullscreen() {
            log.info({
                a: "c",
                p: "o",
                o: "exitFullscreenBtn"
            })
            this.isFullscreen = false;
        },
        selectOcrStatus(id, index) {
            log.info({
                p: "o",
                a: "c",
                o: "selectOcrStatus"
            });
            this.currentitemId = id
            api.SelectOcrStatus(id).then(res => {
                console.log(res);
                if (res.ocrStatus == 1) {
                    this.message = this.content[index].summary
                    this.fileName = res.fileName
                    this.fileType = res.fileType
                    this.fileId = res.id
                    this.filePath = res.filePath
                } else if (res.ocrStatus == -1) {
                    this.message = "识别失败"
                } else if (res.ocrStatus == 0) {
                    this.message = "等待识别"
                }

            }).catch(err => {
                log.error({
                    p: "o",
                    a: "e",
                });
            })
        },
        // 调用轮询函数
        save() {
            this.saveText()
        },
        copyText() {
            log.info({
                p: "o",
                a: "c",
                o: "copyBtn"
            });
            const clipboard = new Clipboard('.icon', {
                text: () => {
                    return this.message
                }
            })
            clipboard.on('success', () => {
                this.$toast('复制成功')
            })
            clipboard.on('error', () => {
                this.$toast('复制失败')
            })
            clipboard.onClick(event)
            clipboard.destroy()
        },
        //朗读
        // toggleSpeak() {
        //     // 创建语音合成器
        //     const utterance = new SpeechSynthesisUtterance(this.message);
        //     // 开始朗读
        //     this.synthesizer.speak(utterance);
        // },
        //无痕模式状态
        // isnoTraceMode() {
        //     let noTraceMode = this.$route.params.noTraceMode
        //     console.log("noTraceMode", noTraceMode)
        //     this.noTraceMode = noTraceMode
        // },
        //返回上一级
        onClickLeft() {
            this.$router.push("/home")
        },
        //分页滑动
        onChange(index) {
            this.pageNo = index + 1;
            this.init();
        },
        //提示按钮
        switchTag(index) {
            this.current = index;
            this.prompts = this.button_data[index].message

        },
        //无痕按钮
        noTrace() {
            log.info({
                p: "o",
                a: "c",
                o: "noTraceBtn"
            });
            let ids = []
            for (let i = 0; i < this.content.length; i++) {
                ids.push(this.content[i].id)
            }
            this.noTraceMode = !this.noTraceMode
            console.log(this.content.id)
            let params = {
                noTraceMode: this.noTraceMode
            }
            // 3.OCR任务文件切换无痕模式
            api.SelectOcrFilesStatus(
                ids, params
            ).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        },
    
    },
    components: {
        NativeShare
    },
}