<template>
    <div>

          <!-- 微信浏览器弹出层 -->
    <div class="guide" @click="handleClose">
      <van-popup class="popup" v-model="showPopup" position="top">

        <div class="jt">
          <img :src="require('@/assets/home/箭头.png')" alt="">
        </div>
        <div class="ts">
          <p>点击右上角选择</p>
          <p>在浏览器打开~~</p>
        </div>

        <div class="icon">
          <div>
            <van-icon class="more" name="more-o" size="40" color="white" />
          </div>
          <div>
            <span class="span">
              <van-button class="btn" :icon="require('@/assets/home/浏览器.png')" size="20">
                在浏览器中打开
              </van-button>
            </span>
          </div>
        </div>


      </van-popup>

    </div>
    <!-- 手机浏览器弹出层 -->
    <!-- iphone -->
    <div class="SimpleModaliphone" @click="handleClose">
      <van-popup class="popup" v-model="iphonePopup" position="top">
        <div class="title">
          <h1>添加服务到桌面</h1>
        </div>
        <div class="one">
          <p>第一步:<span>点击底部工具</span></p>
          <img :src="require('@/assets/home/iphone.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第二步:<span>在弹出框中点击"添加到主屏幕"</span></p>
          <img :src="require('@/assets/home/iphone2.png')" alt="" width="100%">
        </div>
      </van-popup>
    </div>
    <!-- vivo -->
    <div class="SimpleModaliphone" @click="handleClose">
      <van-popup class="popup" v-model="vivoPopup" position="top">
        <div class="title">
          <h1>添加服务到桌面</h1>
        </div>
        <div class="one">
          <p>第一步:<span>点击底部工具</span></p>
          <img :src="require('@/assets/home/vivo1.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第二步:<span>在弹出框中点击"收藏网址"</span></p>
          <img :src="require('@/assets/home/vivo2.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第三步:<span>收藏成功后点击"编辑"按钮</span></p>
          <img :src="require('@/assets/home/vivo3.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第四步:<span>选中"手机桌面"后点击"确定"</span></p>
          <img :src="require('@/assets/home/vivo4.png')" alt="" width="100%">
        </div>
      </van-popup>

    </div>
    <!-- oppo -->
    <div class="SimpleModaliphone" @click="handleClose">
      <van-popup class="popup" v-model="oppoPopup" position="top">
        <div class="title">
          <h1>添加服务到桌面</h1>
        </div>
        <div class="one">
          <p>第一步:<span>点击底部工具</span></p>
          <img :src="require('@/assets/home/oppo1.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第二步:<span>在弹出框中点击"添加书签"</span></p>
          <img :src="require('@/assets/home/oppo2.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第三步:<span>收藏成功后点击"编辑"按钮</span></p>
          <img :src="require('@/assets/home/oppo3.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第四步:<span>选中"桌面"后点击" √ "按钮</span></p>
          <img :src="require('@/assets/home/oppo4.png')" alt="" width="100%">
        </div>
      </van-popup>

    </div>

    <!-- huawei -->
    <div class="SimpleModaliphone" @click="handleClose">
      <van-popup class="popup" v-model="huaweiPopup" position="top">
        <div class="title">
          <h1>添加服务到桌面</h1>
        </div>
        <div class="one">
          <p>第一步:<span>点击底部工具</span></p>
          <img :src="require('@/assets/home/huawei1.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第二步:<span>在弹出框中点击"添加至"</span></p>
          <img :src="require('@/assets/home/huawei2.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第三步:<span>选中"桌面"然后点击"确定"按钮</span></p>
          <img :src="require('@/assets/home/huawei3.png')" alt="" width="100%">
        </div>
      </van-popup>

    </div>

    <!-- 小米 -->
    <div class="SimpleModaliphone" @click="handleClose">
      <van-popup class="popup" v-model="xiaomiPopup" position="top">
        <div class="title">
          <h1>添加服务到桌面</h1>
        </div>
        <div class="one">
          <p>第一步:<span>点击底部工具</span></p>
          <img :src="require('@/assets/home/xiaomi1.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第二步:<span>在弹出框中点击"添加到桌面"</span></p>
          <img :src="require('@/assets/home/xiaomi2.png')" alt="" width="100%">
        </div>
        <div class="one">
          <p>第三步:<span>点击"确定"按钮</span></p>
          <img :src="require('@/assets/home/xiaomi3.png')" alt="" width="100%">
        </div>
      </van-popup>

    </div>
    </div>
</template>

<script>
export default {
    props:["brand"],
    data () {
        return {
        showPopup:false,
        iphonePopup:false,
        vivoPopup:false,
        oppoPopup:false,
        huaweiPopup:false,
        xiaomiPopup:false,
        }
    },
    methods: {
          //识别用户是否在桌面打开
      getHref(){
           const href=window.location.href
           //来自微信游览器
           if(href.includes('#111')){
            if(this.brand=="iphone"){
              this.iphonePopup=true
            }else if(this.brand=="vivo"){
              this.vivoPopup=true
            }else if(this.brand=="huawei"){
              this.huaweiPopup=true
            }else if(this.brand=="xiaomi"){
              this.xiaomiPopup=true
            }else{
              this.oppoPopup=true
            }
              this.$router.push({path:"/home#123"})
           }else if(href.includes('#123')){
            const currentDate = new Date();

            // 将当前日期设置为今天的0时0分0秒
            currentDate.setHours(0, 0, 0, 0);
            
            // 将过期时间设置为明天的0时0分0秒
            const tomorrow = new Date(currentDate);
            tomorrow.setDate(currentDate.getDate() + 1);
            
            // 设置 localStorage 的值并带上过期时间
            localStorage.setItem("openFromDesktop",1)
            localStorage.setItem('expiration', tomorrow.getTime());
               
                
           }
      },
        handleClose(){
        this.xiaomiPopup=false
        this.showPopup=false
        this.iphonePopup=false
        this.vivoPopup=false
        this.huaweiPopup=false
        this.oppoPopup=false
        this.$router.push({path:"/home"})
      },
      open(){
           if(this.brand=="iphone"){
                this.iphonePopup=true
            }else if(this.brand=="vivo"){
              this.vivoPopup=true
            }else if(this.brand=="huawei"){
              this.huaweiPopup=true
            }else if(this.brand=="xiaomi"){
              this.xiaomiPopup=true
            }else{
              this.oppoPopup=true
            }
            this.$router.push({path:"/home#123"})
      },
      weixinOpen(){
        this.showPopup = true
          this.$router.push({
            path: "/home#111"
          })
      }
    },
 
    computed:{
       
    },
    created(){
       this.getHref()
    }
}
</script>

<style lang="less">
  .van-popup--top {
    height: 100%;
    background-color: rgb(51, 51, 51)
  }
</style>
 
<style lang = "less" scoped>
   .SimpleModaliphone{
 
 .title{
    text-align: center;
    margin-top: .3rem;
    font-size: .3rem;
    color: #FFF;
 }

 .one{
  font-size: .18rem;
  margin-left: .2rem;
  margin-right: .2rem;
  margin-top: .3rem;
  p{
    color:red
  }
  span{
    margin-left: .2rem;
    color: white;
  }
  img{
    margin-top: .2rem;
  }
 }
}


.guide{
.jt{
  text-align: right;
  margin-right: .2rem;
}

 .ts{
   border-radius: 30%;
   width: 250px;
   border: 2px dashed ;
   letter-spacing: 3px;
   font-size: .2rem;
   color: #fff;
   margin-top: 20%;
   margin-left: 20%;
   padding-top: .3rem;
   padding-bottom: .2rem;
   text-align: center;
   line-height: .3rem;
 }
 .icon{
   margin-left: 20%;
   display: flex;
   align-items: center;
   .more{
     margin-top: .3rem;
     
   }
   .span{
     margin-bottom: .3rem;
   }

   .btn{
    margin-top: .3rem;
  
     width: 2rem;
     margin-left: .2rem;
     background-color: rgb(197, 198, 193);
     
   }
 }

}
</style>