<template>
    <div class="div">
        <van-nav-bar class="navbar" title="文档圈应用《用户协议》" left-arrow @click-left="onClickLeft" />
        
        <p></p>
        <p>发布日期：2023-04-06 </p>
        <p>生效日期：2023-04-06 </p>
        <p>更新日期：2023-04-06</p>
        <p>应用名称：文档圈 </p>
        <p>《文档圈用户协议》（以下简称"本协议"）是您（或称"用户"，指注册、登录、使用、浏览本服务的个人或组织）与文档圈及其运营合作单位之间关于文档圈产品、程序及服务所订立的协议。
            文档圈在此特别提醒用户认真阅读并充分理解本协议中各项条款，包括免除或者限制文档圈责任的免责条款及对用户的权利限制条款。请您审慎阅读并选择接受或不接受本协议。如您是未满18周岁（或当地法律规定的年龄）的未成年人，请您在监护人的陪同下仔细阅读并充分理解本协议，并在得到监护人的同意后使用。除非您接受本协议的所有条款，否则您无权注册、登录或使用本协议所涉的相关服务。您的注册、登录、使用等行为将视为您对本协议的接受，并同意接受本协议各项条款的约束。
            您对本协议的接受即自愿接受本协议全部条款的约束，包括接受文档圈对任一服务条款随时所做的任何修改。本协议可由文档圈随时更新，更新后的协议条款一旦公布即代替原来的协议条款，届时我们将通过在文档圈网站或应用程序内发布公告或提示的方式进行告知，用户可在本网站及文档圈应用程序内随时查阅最新版协议条款。在文档圈修改本协议相关条款之后，如果您不接受修改后的任意条款，请您立即停止使用文档圈提供的服务。用户继续使用文档圈提供的服务将被视为已接受了修改后的协议。
        </p>

        <h1>一、用户使用规则</h1>

        <p>1、您无需注册用户账号即可浏览文档圈网站或使用文档圈应用程序。但某些网站功能、文档圈产品、程序和服务需要您注册文档圈账户。如果您想使用文档圈网站与文档圈产品、程序和服务的更多功能，您必须注册相应账户并且于注册页面中填写相关的个人信息。您可以按照文档圈网站及应用程序中的说明随时终止使用您的账号，我们将会依据本协议规定保留或终止您的账号。您必须承诺和保证：
        </p>


        <p>（1）您了解并同意，文档圈属于【应用服务产品】，用户须对注册信息的真实性、合法性、有效性承担全部责任，同时有义务在相关信息发生变更时及时通知我们予以更新。如果您提供了任何错误、不实、过时或不完整的资料，或者我们有合理理由怀疑该资料为错误、不实、过时或不完整的，我们有权立即暂停或停止为您提供服务，对此我们免于承担任何责任。
        </p>

        <p>
            （2）您使用文档圈网站与文档圈产品、程序及服务的行为必须合法，您必须为自己注册账户下的一切行为负责，文档圈不会且无法对因用户行为而导致的任何损失或损害承担责任。

        </p>
        <p>

            （3）您应负责维护您的账号登录信息的保密性、确保您的账号不被其他人获取和使用，并对您的账号下产生的所有活动承担责任。您必须将任何有可能触犯法律法规的，未经授权使用的或怀疑为未经授权使用的行为立即通知文档圈，我们不对您因未能遵守上述要求而造成的损失承担责任。

        </p>

        <p>
            2、您必须知悉并确认：
            我们因业务发展需要，可能单方面对文档圈的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销，用户需要自行承担此风险。
            我们提供的服务中可能包括广告等活动，用户同意在使用过程中显示我们及关联方与第三方供应商、合作伙伴提供的广告以及其他活动。
            我们的某些产品服务必须在联网的情况下才可以使用。您必须自行负担个人上网或第三方（包括但不限于电信或移动通讯提供商）收取的通讯费、信息费等相关费用。如涉及电信增值服务，我们建议您与增值服务提供商确认相关费用问题。

        </p>

        <h1>
            二、用户权利及义务
        </h1>

        <p>1、合法使用文档圈网站与文档圈产品、程序及服务的权利；</p>
        <p>2、在您所有的移动通信设备上下载、安装、使用文档圈产品、程序及服务的权利；</p>
        <p>3、用户的文档圈账号所有权归文档圈及其关联公司所有，您完成申请注册手续后，获得文档圈账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。我们因经营需要，有权回收用户的账号；</p>
        <p>4、您有权更改、删除在文档圈上的个人资料、注册信息等，但需注意的是，删除有关信息的同时也会删除任何您储存在系统中的信息，您需自行承担该风险；</p>
        <p>5、您有责任妥善保管注册账户信息及账户密码的安全，您需要对注册账户及密码下发生的所有活动承担法律责任。您同意在任何情况下不使用其他用户的账户或密码。当您怀疑他人未经授权正在使用您的账户时，您应当立即通知文档圈。</p>
        <p>6、权利限制：</p>

        <p>（1）您不得对文档圈网站内容或文档圈产品、程序及服务（包括但不限于内容或产品中的广告或赞助内容）进行任何形式的许可、出售、租赁、转让、发行或做其他商业用途；
        </p>

        （2）您不得以创建相同或竞争服务为目的访问文档圈网站或使用文档圈产品、程序及服务；<br>
        <br>（3）除非法律明文规定，否则您不得对文档圈网站或文档圈产品、程序及服务（包括但不限于内容或产品中的广告或赞助内容）的任何部分以任何形式或方法进行复制、发行、再版、下载、显示、张贴、修改、翻译、合并、利用、分解或反向编译等；
        <br>（4）您已同意在通过上传、发布或以其他方式使用文档圈网站或文档圈产品、程序和服务的使用过程中，您将承担因下述行为所造成的风险而产生的全部法律责任：破坏宪法所确定的基本原则的；危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的；损害国家荣誉和利益的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权益的；含有法律法规、行政规章所禁止的其他内容的。
        <br>（5）您已同意不利用文档圈网站或文档圈产品、程序及服务从事下列行为：上传或发布电脑病毒、蠕虫、恶意代码、故意破坏或改变计算机系统或数据的软件；未授权的情况下，收集其他用户的信息或数据，例如电子邮箱地址等；禁用文档圈的网络连接，给文档圈造成过度的负担或以其他方式干扰或损害网站服务器和网络链接；在未授权的情况下，尝试访问文档圈的服务器或网络链接；干扰、破坏其他用户正常使用文档圈网站或文档圈产品、程序及服务。
        <br>三、第三方
        <br>1、您已知晓并同意我们的服务是基于应用等的技术支持而获得。您已知晓本协议是在您与文档圈之间签订，而非您与上述第三方之间签订。文档圈是基于文档圈网站和文档圈产品、程序及服务所产生的内容、维护、支持服务、保证和由此产生的诉讼等事项的唯一责任人。您已同意遵守且授权给文档圈限制您有条件地使用文档圈网站或文档圈产品、程序及服务。
        <br>2、我们可能会接入第三方网站为您提供部分服务。您需对使用第三方网站和广告时所产生的风险承担法律责任。当您访问第三方网站和广告时，适用第三方提供的用户条款和隐私协议。为了用户使用需要，我们可能会调用、接入第三方提供的内容或者支持用户通过第三方进行使用或访问。但是我们不会也无法保证第三方提供服务及内容的及时性、安全性、准确性、完整性和有效性。
        <br>四、损害赔偿
        <br>1、您已同意无害地使用文档圈网站和文档圈产品、程序及服务，避免文档圈因下述行为或相关行为遭受来自第三方的任何投诉、诉讼、损失、损害、责任、成本和费用（包括但不限于诉讼费、律师费、调查费、鉴定费、公证费、保全费、执行费、差旅费等维权费用及行政罚款、用户赔偿）：您使用文档圈网站和文档圈产品、程序及服务的行为；您违反本协议的行为。否则，您应对此承担全部赔偿责任。
        <br>2、您已同意，除非获得文档圈书面同意，您不得在您与文档圈共同对第三方提起的诉讼中单方和解。
        我们将尽合理努力将此类诉讼、诉讼行为及诉讼进程及时通知您。
        <br>3、在任何情况下，文档圈都不对您或任何第三方因本协议产生的任何间接性、后果性、惩罚性的、偶然的、特殊性或刑罚性的损害赔偿和附带损失、商誉损失及利润损失承担责任。访问、使用文档圈网站和文档圈产品、程序及服务所产生的损坏计算机系统或移动通讯设备数据库的风险将由您自行承担。
        <br>五、免责声明
        <br>1、如发生下述情形，文档圈不承担任何法律责任：
        <br>（1）依据法律规定或相关政府部门的要求提供您的个人信息；
        <br>（2）由于您的使用不当或其他自身原因而导致任何个人信息的泄露；
        <br>（3）任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成的服务中断、受阻等不能满足用户要求的情形；
        <br>（4）用户因第三方如运营商的通讯线路故障、技术问题、网络、电脑故障、系统不稳定及其他因不可抗力造成的损失的情形；
        <br>（5）文档圈网站和文档圈产品、程序及服务明文声明，不以明示、默示或以任何形式对合作公司服务之及时性、安全性、准确性、完整性和有效性做出担保。
        <br>（6）访问、使用文档圈产品、程序及服务所产生的损坏计算机系统或移动通讯设备数据库的风险将由您自行承担。
        <br>2、在任何情况下，文档圈均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用文档圈服务而遭受的附带损失、商誉损失、利润损失承担责任。尽管本协议中可能含有相悖的规定，我们对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您在注册期内因使用文档圈服务而支付给文档圈的费用（如有）。
        <br>六、知识产权
        <br>1、文档圈网站或文档圈产品、程序及服务中所涉及的图形、文字或其组成，以及其他文档圈标志及产品、服务名称，均受著作权、商标权及其他知识产权法律保护。未经相关权利人（含文档圈及其他原始权利人）事先书面同意，用户不得将上述内容以任何方式展示、使用或作其他处理，任何单位及个人不得以任何方式或理由对上述内容的任何部分进行使用、复制、修改、传播、抄录或与其他产品捆绑使用销售，或被用于其他任何商业目的。
        <br>2、除非经过我们的事先书面同意，您未获得权利使用我们的任何知识产权。您保证并承诺尊重我们的知识产权，不会以自己名义，也不会同意、促使或放任任何第三方，为了任何营销、广告、促销或其他目的，在任何法域、以任何方式申请与我们或我们的关联公司商标相似的商标、域名、网站、互联网搜索词或任何商号、服务标志。如出现上述情况，您须将所有相关权利转让给我们，并承担由此产生的所有费用。
        <br>七、修改与终止
        <br>1、修改
        <br>本协议容许变更。如果本协议有任何实质性变更，我们将通过在文档圈网站及文档圈应用程序内发布公告的方式进行通知。本协议变更之后，如果您继续使用文档圈网站或文档圈产品、程序及服务则视为您已知晓此类变更并同意受其约束；
        <br>2、终止
        <br>（1）本协议自您接受之日起生效，在您使用文档圈网站和文档圈产品、程序及服务的过程中持续有效，直至依据本协议终止；
        <br>（2）尽管有上述规定，如果您使用文档圈网站和文档圈产品、程序及服务的时间早于您接受本协议的时间，您在此知晓并同意，本协议于您第一次使用文档圈网站和文档圈产品、程序及服务时生效，除非依据本协议提前终止。
        <br>（3）依据法律的规定，我们保留您使用文档圈网站、文档圈产品、程序及服务、或者文档圈账号的权利。如果您违反了我们的可接受使用政策或本协议的其他规定，无论是否通知，我们拥有未经通知在任何时间以任何原因终止本协议的权利。
        <br>（4）我们可能依照适用法律在任何时候临时性或永久性中止、取消或限制您访问文档圈的全部或部分服务，而不对任何个人或第三方承担任何责任。我们会在采取前述行动前尽力通知您。但若出现如下情形，我们可能在不事先通知您的情况下立即永久性或临时性限制、取消或终止您访问文档圈的全部或部分服务：
        <br>① 您违反或我们有理由认为您可能违反本协议，包括其中包含的任何约定、政策或指南；
        <br>② 您或代理您的任何人进行欺诈或违法活动，或向我们提供虚假或误导信息；
        <br>③ 依照有效法律程序响应执法部门或政府机关提出的要求；
        <br>④ 为执行紧急的系统或硬件维护或更新工作；
        <br>⑤ 因无法预料的技术、安全或业务原因。
        <br>（5）本协议一旦终止，您的文档圈账号即被注销，您使用文档圈网站和文档圈产品、程序及服务的权利也即告终止。您应当知晓您的账号被注销意味着您的用户内容将从我们的活动数据库中删除。文档圈不因终止本协议对您承担任何责任，包括注销您的用户账号和删除您的用户内容。我们也没有义务向您提供您使用文档圈服务所形成的信息资料。
        <br>3、任何文档圈网站或文档圈产品、程序及服务的未来版本、更新或者其他变更将受到本协议约束。
        <br>八、其他
        <br>1、反馈
        <br>（1）您对文档圈提出建议（或称"反馈"），即视为您向文档圈转让"反馈"的全部权利并同意文档圈有权利以任何合理方式使用此反馈及其相关信息。我们将视此类反馈信息为非保密且非专有；
        <br>（2）您已同意您不会向文档圈提供任何您视为保密和专有的信息；
        <br>（3）我们将保留基于我们的判断检查用户内容的权利（而非义务）。无论通知与否，我们有权在任何时间以任何理由删除或移动您的用户内容。同时依据本协议的规定，我们有权保留或终止您的账号。
        <br>2、隐私协议
        <br>详情请查阅我们的《隐私协议》，《隐私协议》为与本协议效力等同且不可分割的一部分。
        <br>3、通知
        <br>您必须提供您最近正在使用的有效的手机号码。您所提供的手机号码无法使用或者因任何原因我们无法将通知送达给您而产生的风险，文档圈不承担任何责任。您已知晓并同意，文档圈发布的公告通知及向您提供的手机号码所发送的包含此类通知的手机短信构成有效通知。
        <br>4、适用法律
        <br>本协议适用中华人民共和国法律。如果双方发生纠纷，应本着友好的原则协商解决；如协商不成，应当向上海市徐汇区有管辖权的人民法院提起诉讼。
        <br>5、独立性
        <br>若本协议中的某些条款因故无法适用，则本协议的其他条款继续适用且无法适用的条款将会被修改，以便其能够依法适用。
        <br>6、完整性
        <br>（1）本协议（包括隐私协议）是您和文档圈之间关于文档圈网站和文档圈产品、程序及服务相关事项的最终的、完整的、排他的协议，且取代和合并之前当事人关于此类事项（包括之前的最终用户许可、服务条款）的讨论和协议。
        <br>（2）每部分的题目只为阅读之便而无任何法律或合同义务。
        <br>（3）除非文档圈书面同意，您不得转让本协议所规定的权利义务。任何违反上述规定企图转让的行为均属无效。

        <br>北京源存科技有限公司
       
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
           
        
        },
    }
</script>

<style lang="less" scoped>
    .div {
        font-size: .18rem;
    }

    .navbar {
        position: sticky;
        top: 0;
        z-index: 1;
    }
</style>