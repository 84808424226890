import AppTabbar from "@/components/AppTabbar";
import api from "@/request/api"
import log from "@/request/log"
import {
    Dialog,
    Toast
} from 'vant';
export default {
    data() {
        return {
            title: "",
            rows: [],
            result: [],
            startTime: null,
        }
    },
    components: {
        Dialog,
        AppTabbar
    },
    created() {
        this.getList()
    },
    mounted() {
        log.info({
            p: "e",
            a: "m",
          });
          // 记录页面打开时间
          this.startTime = new Date()
    },
    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
          p: "e",
          a: "u",
          d: stayTime
        });
      },
    methods: {
        select(){
            log.info({
                p: "e",
                a: "c",
                o: "checkedBtn"
              });
            this.$refs.checkbox.$el.click() // 触发复选框的点击事件
        },
        getList() {
            api.GetFileList().then(res => {
                for (let i = 0; i < res.content.length; i++) {
                    let date = new Date(res.content[i].createdAt);
                    let date2 = date.toLocaleDateString().replace(/\//g, "/") + " " + date.toTimeString().substring(0, 5);
                    if (res.content[i].summary) {
                        res.content[i].summary = res.content[i].summary.replaceAll("\n", '');
                    }
                    if (res.content[i].comment == null) {
                        res.content[i].comment = res.content[i].fileName
                    }
                    res.content[i].createdAt = date2
                }
                this.rows = res.content
            }).catch(err => {
                log.error({
                    p: "e",
                    a: "e",
                  });
            })
        },
        onClickLeft() {
            this.$router.push("/file")
        },
        checkboxBtn() {
            this.title = "已选择" + this.result.length + "项";
        },
        onClickRight() {
            log.info({
                p: "e",
                a: "c",
                o: "delBtn"
              });
            if (this.result.length == 0) {
                Dialog.alert({
                    title: '温馨提示',
                    message: '请选择要删除的文件'
                })
            } else {
                Dialog.confirm({
                    title: '温馨提示',
                    message: '确定要删除' + this.result.length + '个文件及OCR识别结果吗?'
                }).then(() => {
                    api.deleteFile(
                        this.result
                    ).then(res => {
                        if (res.state == "true") {
                            this.$toast("删除成功！")
                            this.getList()
                            this.result = []
                        } else {
                            Toast("删除失败！")
                        }
                    }).catch(err => {
                        log.error({
                            p: "e",
                            a: "e",
                          });
                    })
                }).catch(() => {});
            }
        }
    },
}