import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => {})
};
Vue.use(VueRouter)



const routes = [
  
  {
    path: '/',
    redirect:"/home"
    
  },
  {
    path: '/home',
    name: 'Home',
    
    meta:{
      activeNum:0,
      isShowTabbar:true  // 控制Tabbar的显示
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
  },

  {
    path: '/ocrPopup',
    name: 'OcrPopup',
    component: () => import(/* webpackChunkName: "ocrPopup" */ '../views/ocr/OcrPopup.vue')
    
  },

  {
    path: '/file',
    name: 'File',
    meta:{
      activeNum:1,
      isShowTabbar:true  // 控制Tabbar的显示
    },
    component: () => import(/* webpackChunkName: "file" */ '../views/file/File.vue'),  
  },
  {
    path: '/tagFile',
    name: 'TagFile',
    component: () => import(/* webpackChunkName: "tagFile" */ '../views/tagFile/TagFile.vue')
  },

  {
    path: '/editFile',
    name: 'EditFile',
    meta:{
      activeNum:1,
      isShowTabbar:true  // 控制Tabbar的显示
  },
    component: () => import(/* webpackChunkName: "editFile" */ '../views/editFile/EditFile.vue')
  },

  {
    path: '/sign',
    name: 'Sign',
    meta:{
      activeNum:2,
      isShowTabbar:true  // 控制Tabbar的显示
    },
    component: () => import(/* webpackChunkName: "sign" */ '../views/sign/Sign.vue')
  },


  {
    path: '/user',
    name: 'User',
    meta:{
      activeNum:3,
      isShowTabbar:true  // 控制Tabbar的显示
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/user/User.vue')
  },

  {
    path: '/share',
    name: 'Share',
    component: () => import(/* webpackChunkName: "share" */ '../views/share/Share.vue')
  },

  {
    path: '/small',
    name: 'Small',
    component: () => import(/* webpackChunkName: "share" */ '../views/small/Small.vue')
  },

  {
    path: '/ofd',
    name: 'ofd',
    component: () => import(/* webpackChunkName: "share" */ '../views/ofd/ofd.vue')
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import(/* webpackChunkName: "share" */ '../views/invoice/invoice.vue')
  },
  
  {
    path: '/ocr',
    name: 'ocr',
    component: () => import(/* webpackChunkName: "share" */ '../views/mocr/ocr.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
  },

  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () => import(/* webpackChunkName: "userAgreement" */ '../views/userAgreement/userAgreement.vue')
  },

  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/privacyPolicy/privacyPolicy.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
