import {Getaccount,signIn,gettingAward} from "@/request/api"
import log from "@/request/log"
import { Toast } from "vant";
export default {
    data() {
        return {   
            startTime: null, 
            status:{},     
            show:false,
            point:"",
            csd:"",
            tsd:"",
            todaySigned:"",
            isToday:"已领",
            num:"",
            arrActive: [],
            bg: require("@/assets/sign/积分背景@2x.png"),
            list: [],
            tasks:[],
            task:[
                {
                    id:1,
                    img:require('@/assets/sign/icon-关联手机号码@2x.png'),
                    title:"关联手机号码",
                    description:"首次关联手机号码",
                    point:"+10积分"
                },
                {
                    id:2,
                    img:require('@/assets/sign/icon-邀请朋友.png'),
                    title:"邀请朋友注册",
                    description:"不限领取次数",
                    point:"+10积分"
                },           
            ]    
        }
    },
    methods: {
        getImg(){         
                this.tasks.forEach((item) => {
                    if(item.code=="addToDesktop"){
                        item.img=require('@/assets/sign/icon-添加到桌面.png'); 
                    }else if(item.code=="openFromDesktop"){
                        item.img=require('@/assets/sign/icon-桌面打开.png');    
                        
                    }
                    else if(item.code=="uploadAFile"){
                        item.img=require('@/assets/sign/icon-上传文档.png');
                    }                  
                })        
        },
     
        Getaccount(){
            Getaccount().then(res=>{
                console.log(res)
                this.point=res.point
                this.csd=res.csd
                this.tsd=res.tsd
                this.tcd=res.tcd
                this.todaySigned=res.todaySigned
                this.list=res.singInDays
                this.tasks=res.tasks 
                this.getImg()
               
            }).catch(err=>{
                log.error({
                    p: "s",
                    a: "e",
                });
            })
        },
        closeShow(){
            this.show = false;
        },
        signInToday() {
        },
        editBtn(){
            log.info({
                p: "s",
                a: "c",
                o: "editBtn"
              });
            const phoneNumber= localStorage.getItem("phoneNumber")
            if(phoneNumber){
                if(this.todaySigned==false){
                    signIn().then(res=>{
                       // console.log(res)
                        this.point=res.point
                        this.csd=res.csd
                        this.tsd=res.tsd
                        this.tcd=res.tcd
                        this.todaySigned=res.todaySigned
                        this.list=res.singInDays                 
                        this.show=true 
                        this.num=res.singInDays[res.csd-1].point
                       
                    }).catch(err=>{
                        log.error({
                            p: "s",
                            a: "e",
                          });
                    })        
                }    
            }else{
                Toast('请您先登录')
                this.$router.push('/login')
            }
         
        }, 
        
        ReceiveBtn(status,code){
            log.info({
                p: "s",
                a: "c",
                o: "ReceiveBtn"
              });
            const phoneNumber= localStorage.getItem("phoneNumber")
            const uploadfile=localStorage.getItem("uploadfile")
            if(phoneNumber){
        
            const open= localStorage.getItem("openFromDesktop")
            const expiration=localStorage.getItem("expiration")
            if(expiration&&Date.now()>parseInt(expiration,10)){
                      localStorage.removeItem('open')
                      localStorage.removeItem("expiration")
                      //alert("已过期")
                      return
            }
            
            if(code=="openFromDesktop"){
                if(open==1){
                    gettingAward({
                        code: "openFromDesktop"
                    }).then(res=>{
                      console.log(res)
                      this.Getaccount()
                    }).catch(err=>{
                        log.error({
                            p: "s",
                            a: "e",
                        });
                    })
                }else{
                    Toast("请从桌面打开文档圈")
                }
               
            }else if(status==0&&code=="uploadAFile"){
                console.log(uploadfile)
                if(uploadfile=="true"){
                    gettingAward({
                        code: "uploadAFile"
                    }).then(res=>{
                      console.log(res)
                      this.Getaccount()
                    }).catch(err=>{
                        log.error({
                            p: "s",
                            a: "e",
                        });
                    })
                }else{
                    Toast("请先上传一份文档")
                }
               
            }else if(status==1&&code=="addToDesktop"){
               const addToDesktop= localStorage.getItem("addToDesktop")
                if(addToDesktop=="true"){
                    gettingAward({
                        code: "addToDesktop"
                    }).then(res=>{
                      console.log(res)
                      this.Getaccount()
                    }).catch(err=>{
                        log.error({
                            p: "s",
                            a: "e",
                        });
                    })
                }else{
                    Toast('请前往首页点击右上角加号选中添加到桌面')
                }
             
            }
          }else{
            Toast('请您先登录')
            this.$router.push('/login')
          }
       },

       taskBtn(id){
        log.info({
            p: "s",
            a: "c",
          });
             //alert(id)
             let hash = this.arrActive.findIndex(item => {
                return item == id
            })
            if (hash > -1) {      
            } else {
                this.arrActive.push(id)
                let str=this.task[id-1].point;
                let str2=str.replace(/[^\d]/g, " ");
                this.point=parseInt(this.point)+parseInt(str2)
            }
       }
    },   
    created() {
        this.Getaccount()
    },
    mounted() {
        log.info({
            p: "s",
            a: "m",
          });
          // 记录页面打开时间
          this.startTime = new Date()
    },
    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
          p: "s",
          a: "u",
          d: stayTime
        });
      },
}
