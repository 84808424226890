import Vue from 'vue';
import {IndexBar, IndexAnchor,Uploader,NoticeBar,NavBar,
        Switch,Skeleton,Sidebar, SidebarItem ,Progress,Tabbar, 
        TabbarItem, Toast,Button,Search, Swipe, SwipeItem,Icon,
        Tag,List,Cell,DropdownMenu,DropdownItem,Empty,Checkbox,
        CheckboxGroup,Col,Sku, Row, Grid, GridItem,Form,Field ,
        GoodsAction, GoodsActionIcon, GoodsActionButton,Card,
        SubmitBar,Stepper,SwipeCell,CouponCell, CouponList,
        RadioGroup, Radio ,Divider,Overlay,Popover,Dialog,
        ActionSheet,Tab, Tabs,Popup,Collapse, CollapseItem
        ,Loading,Image as VanImage,Lazyload,Notify,CellGroup,ShareSheet} from 'vant';

Vue.use(ShareSheet);
Vue.use(CellGroup);
Vue.use(Notify);
Vue.use(Lazyload);
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Empty);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Popover);
Vue.use(Overlay);
Vue.use(Divider);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(CouponCell);
Vue.use(CouponList);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Skeleton);
Vue.use(Switch);
Vue.use(NoticeBar);
Vue.use(Uploader);
Vue.use(NavBar);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Progress);
Vue.use(SwipeCell);
Vue.use(Stepper);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Sku);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Card);
Vue.use(Form);
Vue.use(Field);
Vue.use(SubmitBar);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Toast);
Vue.use(Empty);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(List);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Search);
Vue.use(Button);
Vue.use(Cell);