import axios from "axios";
const instance = axios.create({
    //baseURL:"https://web-api.filequan.com/",
    //baseURL:"https://test-web-api.filequan.com/",
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000,  
});
// 拦截器 
instance.interceptors.request.use(config=>{
    //版本号
    config.headers["apiVersion"] = "0.1.0"
    let ticket = localStorage.getItem("ticket");
    if(ticket){
        // 携带登录凭证发起请求
        config.headers["ticket"] =ticket
    }
    return config
},err=>{
    return Promise.reject(err)
})
instance.interceptors.response.use(res=>{
    return res.data   
},err=>{
    return Promise.reject(err)
})


export default instance