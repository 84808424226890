import "@/vantui"
import axios from 'axios'
import "reset-css"
import Vue from 'vue'
import ProgressBar from 'vue-simple-progress'
import App from './App.vue'
import router from './router'
import store from './store'
import { Toast } from 'vant'
import VueClipboard from 'vue-clipboard2'
import '@/assets/css/global.css'
Vue.prototype.$axios = axios;  //写成原型属性，

new Vue({
  router,
  store,
  ProgressBar,
  Toast,
  VueClipboard,
  render: h => h(App)
}).$mount('#app')

