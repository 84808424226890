import {getUser} from "@/request/api"
import api from "@/request/api"
import log from "@/request/log"
import {
    Dialog, Toast
} from 'vant';
export default {
    data() {
        return {
            startTime: null,
            name:"临时用户",
            company:"未设置",
            fileList:"",
            point:0,
            image: require("@/assets/user/矩形14@2x.png"),
            actions:[{
                name: '修改我的名称',
            }],
            action2:[{
                name: '修改单位名称',
            }],
            user:{

            },
            showName:false,
            showNamePopup:false,
            updateName:"",
            updateCompany:"",
            showCompany:false,
            showCompanyPopup:false,
            DOA:[
                {
                    title:"微信",
                    value:"去授权" 
                },
                {
                    title:"微博",
                    value:"去授权"
                },
                {
                    title:"抖音",
                    value:"去授权"
                },
                {
                    title:"哔哩哔哩",
                    value:"去授权"
                },
            ]

        }
    },
    mounted() {
        log.info({
            p: "u",
            a: "m",
          });
          // 记录页面打开时间
          this.startTime = new Date()
    },
    beforeDestroy() {
        // 计算页面停留时间并记录到后端
        const endTime = new Date()
        const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
        log.info({
          p: "u",
          a: "u",
          d: stayTime
        });
      },
    components: {
        Dialog,
       
    },
    created() {
        this.getUser()
    },
    methods: {
        //
        onSelect(action){
            log.info({
                p: "u",
                a: "c",
                o: "update"
              });
            if(action.name=="修改我的名称"){
                 this.showNamePopup=true
                 this.updateName=this.name
            }else if(action.name=="修改单位名称"){
                this.showCompanyPopup=true
                this.updateCompany=this.company
            }
           
        },
        onClickLeft(){
            this.showNamePopup=false
            this.showCompanyPopup=false
        },
        onClickRight(){
            if(this.updateName==""){
                Toast('名称不能为空')
                return
            }     
            api.updateUser({     
                name:this.updateName,
              
            }).then(res=>{
                Toast('修改成功')
                this.showNamePopup=false
                this.getUser()
            }).catch(err=>{
                log.error({
                    p: "u",
                    a: "e",
                  });
                Toast('修改失败')
            })


        },

        onClickCompany(){
            if(this.updateCompany==""){
                Toast('单位名称不能为空')
                return
            }     
            api.updateUser({     
                company:this.updateCompany
            }).then(res=>{
                Toast('修改成功')
                this.showCompanyPopup=false
                this.getUser()
            }).catch(err=>{
                log.error({
                    p: "u",
                    a: "e",
                  });
                Toast('修改失败')
            })


        },

        //获取用户资料
        getUser(){
            getUser().then(res=>{
                console.log(res)
                this.user=res
                
                if(res.name!=null){
                    this.name=res.name
                }
                if(res.company!=null){
                    this.company=res.company
                }
                if(res.point!=null){
                    this.point=res.point
                }
                
            }).catch(err=>{
                log.error({
                    p: "u",
                    a: "e",
                  });
            })
        },

        //隐私政策
        toprivacy(){
            log.info({
                p: "u",
                a: "c",
                o: "toprivacyBtn"
              });
                this.$router.push("/privacyPolicy")
        },
        //用户协议
        toagreement(){
            log.info({
                p: "u",
                a: "c",
                o: "toagreementBtn"
              });
            this.$router.push("/userAgreement")
        },
        sign(){
            log.info({
                p: "u",
                a: "c",
                o: "signBtn"
              });
            this.$router.push("/sign")
        },
   
    }
}