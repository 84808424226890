


<template>
    <div class="div">
        <van-nav-bar class="navbar" title="应用《隐私协议》" left-arrow @click-left="onClickLeft" />
      
        <br>开发者：北京源存科技有限公司
        <br>发布日期：2023-04-06
        <br>生效日期：2023-04-06
        <br>更新日期：2023-04-06
        <br>产品名称：{{app_name}}
        <br>产品类别：应用
        <br>引言
        <br>北京源存科技有限公司，以下简称“我们”）旗下应用{{app_name}}重视用户的隐私保护。您在使用本应用的其他服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私协议》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私协议》与您所使用的本应用服务息息相关，希望您仔细阅读，在需要时，按照本《隐私协议》的指引，作出您认为适当的选择。您使用或继续使用我们的服务，即意味着您同意并认可我们按照本《隐私协议》收集、使用、储存和分享您的相关信息。
        <br>如果您决定使用我们产品提供的服务，本协议用于告知我们对于用户的隐私如何收集、使用和披露。如果您选择使用我们提供的服务，则表示您同意收集和使用与此政策相关的信息。我们收集的个人信息用于提供和改进服务。除非本隐私政策中另有说明，否则我们不会与任何人一起使用或分享您的信息。
        <br>一、我们收集的信息
        <br>1、收集信息的目的：我们仅会出于业务功能需要，收集和使用您的用户信息，收集用户信息的目的在于向您提供产品或服务，包括以下典型场景：①用户身份验证；②个性化内容展示；③法律法规要求的凭证记录。
        <br>2、收集信息的方式： 为了更好地改进产品体验，同时持续给用户提供最佳的服务。在不涉及用户个人隐私及敏感数据的前提下，我们提供服务时，我们可能会通过以下方式收集、储存和使用下列与您有关的信息。
        <br>（1）您主动提供的信息： 您在使用我们的服务时，向我们提供的相关个人信息;
        <br>（2）我们在您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息;
        <br>（3）其他方分享的您的信息： 其他方使用我们的服务时所提供有关您的共享信息。
        <br>3、我们获取的您信息的内容及范围： 我们可能收集您的如下信息：
        <br>（1）设备信息，是指您使用我们的服务时，所使用的相关设备的有关信息，包括：①您的设备品牌、硬件型号；②操作系统版本、操作系统语言；③您手机的国际移动设备身份码IMEl、网络设备的硬件地址MAC、国际移动用户识别码IMSI、以及可能由运营商网络得到的手机号码在内的移动网络信息等。
        <br>（2）日志信息，指您使用我们的服务时，在应用中的行为数据，包括： ①设备或软件信息，例如您的移动设备用于接入我们服务的其他程序所提供的配置信息、您的IP地址和设备品牌及所用的版本和设备识别码；②
        您通过我们的服务进行通讯的信息，例如曾经通讯的账号，以及通讯时间、数据和时长; ③您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。
        <br>（3）您的身份识别信息，指您使用我们的服务时，能够识别用户身份的信息，包括：①用户名和口令；②用户的手机号码；③通过第三方（如社交软件）登录时所提供的信息
        <br>（4）您的地理位置信息，指您在使用我们的产品和服务时所处的地理位置，包括：①根据您的授权获取设备提供的地理位置信息；②根据您的设备网络特征（如IP地址）推测出的大致地理位置信息
        <br>4、我们收集敏感信息的必要性：我们保证会依法对收集后的用户信息进行去标识化或匿名化处理，上述信息收集中所包含敏感信息将会用于：
        <br>（1）身份验证：依据您提供的身份验证相关信息对访问的合法性进行验证；
        <br>（2）业务功能需要：依据这些信息展现或记录相关信息，例如根据地理位置显示用户所需的信息；
        <br>（3）法律法规要求的日志信息留存。
        <br>5、信息收集（包含敏感信息）对个人权益的影响：收集用户信息的目的在于向您提供产品或服务，您有权自行选择是否提供该信息，但多数情况下，如果您不提供，我们可能无法向您提供相应的服务，也无法回应您遇到的问题。
        <br>二、我们如何使用收集到的信息
        <br>我们可能将在向您提供服务的过程之中将所收集的信息用 作下列用途：
        <br>1、向您提供服务；
        <br>2、在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
        <br>3、帮助维护、保护和改进我们的产品；帮助我们设计新服务，改善我们现有服务；
        <br>4、向您提供与您更加相关的广告以替代普遍投放的广告；
        <br>5、评估我们服务中的广告和其他促销及推广活动的效果，并加以改善。
        <br>三、我们可能分享的信息
        <br>1、未经您同意，我们以及我们的关联公司不会与任何第三方分享您的个人信息，但以下情形除外： 我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商分享，用作下列列用途：
        <br>（1）向您提供我们的服务；
        <br>（2）实现“我们可能如何使用信息"部分所述目的；
        <br>（3）履行我们在本《隐私协议》中的义务和行使我们的权利;
        <br>（4）理解、维护和改善我们的服务。
        <br>如我们或我们的关联公司与上述任何第三方分享您的个人 信息，我们将努力确保这些第三方在使用您的个人信息时 遵守本《隐私协议》及我们要求其遵守的其他适当的保密 和安全措施。
        <br>2、随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。
        <br>3、我们或我们的关联公司还可能为以下需要而保留、保存 或披露您的个人信息：
        <br>（1）遵守适用的法律法规；
        <br>（2）遵守法院命令或其他法律程序的规定；
        <br>（3）遵守相关政府机关的要求；
        <br>（4）为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们或我们的集团公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
        <br>四、信息安全
        <br>我们仅在本《隐私协议》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。
        我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范国外的因素而出现问题。
        <br>五、广告服务
        <br>我们可能使用您的相关信息，向您提供与您更加相关的广告。
        <br>六、您的权利
        <br>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的用户信息行使以下权利：
        <br>1、访问您的用户信息
        <br>您有权访问您的用户信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以发送电子邮件至280341537@qq.com。我们将在30天内回复您的访问请求。
        <br>对于您在使用我们的产品或服务过程中产生的其他用户信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请发送电子邮件至280341537@qq.com。
        <br>2、更正您的用户信息
        <br>当您发现我们处理的关于您的用户信息有错误时，您有权要求我们作出更正。您可以通过“（一）访问您的用户信息”中罗列的方式提出更正申请。我们将在30天内回复您的更正请求。
        <br>3、删除您的用户信息
        <br>在以下情形中，您可以向我们提出删除用户信息的请求：
        <br>（1）如果我们处理用户信息的行为违反法律法规；
        <br>（2）如果我们收集、使用您的用户信息，却未征得您的同意；
        <br>（3）如果我们处理用户信息的行为违反了与您的约定；
        <br>（4）如果您不再使用我们的产品或服务，或您注销了账号；
        <br>（5）如果我们不再为您提供产品或服务。
        <br>我们将会根据您的删除请求进行评估，若满足相应规定，我们将会采取相应步骤进行处理。当您向我们提出删除请求时，我们可能会要求您进行身份验证，以保障账户的安全。当您从我们的服务中删除信息后，因为适用的法律和安全技术，我们可能不会立即从备份系统中删除相应的信息，我们将安全存储您的信息直到备份可以清除或实现匿名化。
        <br>4、改变您授权同意的范围
        <br>每个业务功能需要一些基本的用户信息才能得以完成。对于用户信息的收集和使用，您可以随时给予或收回您的授权同意。您可以发送电子邮件至280341537@qq.com，我们将在30天内回复您的变更授权同意请求。
        <br>当您收回同意后，我们将不再处理相应的用户信息。同时也请您注意，您撤销授权同意可能会导致某些后果，例如我们可能无法继续为您提供相应的服务或特定的功能，但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。
        <br>5、用户信息主体注销账户
        <br>您随时可注销此前注册的账户，您可以发送电子邮件至280341537@qq.com，我们将在30天内回复您的注销账户请求。
        <br>在注销账户之后，我们将停止为您提供产品或服务并依据您的要求，删除或匿名化您的信息，法律法规另有规定的除外。这也将可能导致您失去对您账户中数据的访问权，请您谨慎操作。
        <br>6、用户信息主体获取用户信息副本
        <br>您有权获取您的用户信息副本，您可以发送电子邮件至280341537@qq.com，我们将在30天内回复您的获取请求。
        <br>在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的用户信息副本传输给您指定的第三方。
        <br>7、约束信息系统自动决策
        <br>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。
        <br>8、响应您的上述请求
        <br>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在三十天内作出答复。
        <br>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。也请您理解，出于安全保障的考虑、相关法律法规的要求或技术上的限制，对于您的某些请求我们可能无法做出响应，例如以下情形：
        <br>（1）与用户信息控制者履行法律法规规定的义务相关的；
        <br>（2）与国家安全、国防安全直接相关的；
        <br>（3）与公共安全、公共卫生、重大公共利益直接相关的；
        <br>（4）与犯罪侦查、起诉、审判和执行判决等直接相关的；
        <br>（5）用户信息控制者有充分证据表明用户信息主体存在主观恶意或滥用权利的；
        <br>（6）出于维护用户信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br>（7）响应用户信息主体的请求将导致用户信息主体或其他个人、组织的合法权益受到严重损害的；
        <br>（8）涉及商业秘密的。
        <br>七、隐私协议的适用范围
        <br>除某些特定服务外，我们所有的服务均适用本《隐私协议》。但针对某些特定服务的特定隐私协议，将更具体地说明我们在该服务中如何使用您的信息。该特定服务的隐私协议构成本《隐私协议》的一部分。如相关特定的服务的隐私协议与本
        《隐私协议》有不一致之处，适用该特定服务的隐私政策。
        <br>八、隐私协议的适用例外
        <br>我们的服务可能包括或链接至第三方提供的社交媒体或其他服务(包括网站）。例如：
        <br> 1、您利用“分享"键将某些内容分享到我们的服务，或您利用第三方连线服务登录我们的服务。这些功能可能会收集您的相关信息(包括您的日志信息），并可能在您的电脑装置cookies，从而正常运行上述功能;
        <br>2、我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站。 该第三方社交媒体或其他服务可能由相关的第三方或我们运营。您使用该第三方的社交媒体服务或其他服务（包括
        有向该第三方提供的任何个人信息)，须受该第三方的服务条款及隐私政策（而非《通用服务条款》或本《隐私协议》）约束，您需要仔细阅读其条款。本《隐私协议》仅适用于我们所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任。
        <br>九、免责声明
        <br>1、我们不会在未经用户合法授权时，公开、编辑或透露用户的上述信息，除非有下列情况需要：（1）依照国家有关法律、法规规定; （2）执行适用的服务条款(包括调查可能存在的违规情
        況）；(3）查找、预防或处理欺诈、安全或技术方面的问题。
        <br>2、发生以下不可抗力情形，我们不承担任何责任，但本公司将尽最大努力维护您的权益，并将及时通知到您： （1）黑客攻击： （2）电信运营商导致之重大影响； (3）因政府管制而造成网络、网站关闭;（4）病毒侵袭；
        （5）自然灾害、战争等其他不能合理控制、不可预见或即使预见亦无法避免的事件。
        <br>十、未成年人使用我们的服务
        <br>我们鼓励父母或监护人指导未满十八岁的未成年人使用我们的服务。未成年人应该在父母或监护人指导下阅读本 《隐私协议》，并建议未成年人在提交个人信息之前寻求 父母或监护人的同意和指导。
        <br>十一、变更或修订
        <br>根据产品的发展，我们可能适时修订本《隐私协议》的条款，该等修订构成本《隐私协议》的一部分。如该等修订造成您在本 《隐私协议》下权利的实质减少我们将在修订生
        效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私协议》的约束。
        <br>十二、联系我们
        <br>若您对提高本产品服务有任何疑问、意见和建议，欢迎垂询。
        <br>开发者信息：北京源存科技有限公司


    </div>
</template>

<script>
    export default {
        data() {
            return {

                app_name: "文档圈"
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            }
        },
    }
</script>

<style lang="less" scoped>
    .div {
        font-size: .18rem;
    }

    .navbar {
        position: sticky;
        top: 0;
        z-index: 1;
    }
</style>