import { Toast } from "vant";
import { Notify } from 'vant';
import api from "@/request/api"
import {getTags} from "@/request/api"
import log from "@/request/log"

export default {


  data() {
    return {
      selectedItem:-1,
      selected: [] ,// 维护选中状态的数组
      file:{},
      buttonDisabled:true,
      RemarkDisabled:true,
      remark:"",
      inputTagShow:false,
      suggestions: [],
      tagPlain: -1,
      myTags: [],
      selectedTags: [],
      Newtag: '',
      startTime: null,
    }
  },

  methods: {
    
    handleClick(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags.splice(this.selectedTags.indexOf(tag), 1)
      } else if(!this.selectedTags.includes(tag)&&this.myTags.includes(tag)==true){
        this.selectedTags.push(tag)
      }else{
        this.selectedTags.push(tag)
        this.myTags.push(tag)
        
      }
      //console.log("selectedTags",this.selectedTags)
      console.log("myTags",this.myTags)
    },
    myTagsClick(tag){
      if (this.selectedTags.includes(tag)) {
        this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
      } else {
        this.selectedTags.push(tag)
      }

      //console.log("myTags",this.myTags)
    },

  
    //获取备注名称
    getrRemark(){
      this.file = JSON.parse(this.$route.query.file)
      console.log("file",this.file)
      if(this.file.comment.length>20){
        let str = this.file.comment
        this.remark = str.slice(0, 20);
        //console.log(this.remark)
      }else{
        this.remark=this.file.comment
      }
      this.selectedTags=this.file.tags
    },

    //修改标签
    updateTags(){
      const params={
        tags:this.myTags
      }
      api.updateDetail(
         this.file.id,params
      ).then(res=>{
         this.getTags()
      }).catch(err=>{
        log.error({
          p: "t",
          a: "e",
        });
      })
    
     },

    //修改备注
    updateDetail(){
      const params={
        memo:this.remark,
        tags:this.selectedTags
      }
      api.updateDetail(
         this.file.id,params
      ).then(res=>{
        this.$router.push('/file');
        Toast('备注修改成功')
      }).catch(err=>{
        log.error({
          p: "t",
          a: "e",
        });
      })
    
     },
     //获取标签
     getTags(){
          getTags().then(res=>{
            this.suggestions=res.suggestions
            this.myTags=res.myTags
          }).catch(err=>{
            log.error({
              p: "t",
              a: "e",
            });
          })
     },

   

    onClickLeft() {
      if(this.remark.length==0){
           this.$router.push('/file');
      }else{
        this.updateDetail()
    }
  },
    addTag() {
      log.info({
        p: "t",
        a: "c",
        o: "addTagBtn"
      });
      if(this.Newtag==""){
        this.$toast('请输入标签')
         return false
      }
      if (this.myTags.indexOf(this.Newtag) === -1) {
         this.myTags.push(this.Newtag)
         this.selectedTags.push(this.Newtag)  
      }else{
        this.$toast('该标签已存在')
      }
      
      this.Newtag=""
    },
    close(index) {
      this.tags.splice(index, 1);
    },

    //备注输入判断
    onInput(value){
      if (value) {
        this.RemarkDisabled = false  // 输入框有值，按钮可用
      } else {
        this.RemarkDisabled = true  // 输入框无值，按钮不可用
      }
            if(value.length>20){
              this.remark = value.slice(0, 20)
              //Notify('不能超过20个字');
              Toast('备注不能超过20个字')
              return
            }
    },
    //完成按钮
    updateBtn(){
      log.info({
        p: "t",
        a: "c",
        o: "updateBtn"
      });
      this.updateDetail()
    },
  
    //新建标签输入框按钮样式
    onChange(value){
      if (value) {
        this.buttonDisabled = false  // 输入框有值，按钮可用
      } else {
        this.buttonDisabled = true  // 输入框无值，按钮不可用
      }

      if(value.length>10){
        this.Newtag = value.slice(0, 10)
        //Notify('不能超过20个字');
        Toast('标签不能超过10个字')
        return
      }

    }
  
  },
  computed: {
   
  },
  created() {
    this.getrRemark()
    this.getTags()
  },
  mounted() {
    log.info({
      p: "t",
      a: "m",
    });
    // 记录页面打开时间
    this.startTime = new Date()
  },
  beforeDestroy() {
    // 计算页面停留时间并记录到后端
    const endTime = new Date()
    const stayTime = Math.floor((endTime - this.startTime) / 1000) // 单位：秒
    log.info({
      p: "t",
      a: "u",
      d: stayTime
    });
  },
}